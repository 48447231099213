import store from '@/core/services/store'

export const producoes = {
  list: (empresaId, showTodasLabel = true, administrativo = false) => {
    const producoesLista = []

    if (showTodasLabel) {
      const todosEmpresaId = [].concat(empresaId).length === 1 ? [].concat(empresaId)[0] : ''
      producoesLista.push({ id: '', empresa_id: todosEmpresaId, svi: '', nome: 'Todas' })
    }

    const producoes = store.getters.currentUser.producoes
      .filter(function (producao) {
        if (!administrativo && producao.administrativo) {
          return false
        }

        if (!empresaId) {
          return true
        }

        return [].concat(empresaId).includes(producao.empresa_id)
      })
      .sort((a, b) => {
        return a.sigla > b.sigla ? 1 : -1
      }).sort((a, b) => {
        if (a.administrativo === b.administrativo) {
          return 0
        }

        return !a.administrativo && b.administrativo ? -1 : 1
      }).map(function (producao) {
        return {
          id: producao.id,
          nome: producao.sigla,
          empresa_id: producao.empresa_id,
          svi: producao.usu_codsvi,
          endereco_id: producao.endereco_id,
        }
      })

    return producoesLista.concat(producoes)
  },

  listProducoesBySvi: (codSvi, showTodasLabel = true, administrativo = false) => {
    const producoesLista = []

    if (showTodasLabel) {
      const todosCodSvi = [].concat(codSvi).length === 1 ? [].concat(codSvi)[0] : ''
      producoesLista.push({ id: '', producao_id: '', empresa_id: '', svi: todosCodSvi, nome: 'Todas' })
    }

    const producoes = store.getters.currentUser.producoes
      .filter(function (producao) {
        if (!administrativo && producao.administrativo) {
          return false
        }

        if (!codSvi) {
          return false
        }

        return [].concat(codSvi).includes(producao.usu_codsvi)
      })
      .sort((a, b) => {
        return a.sigla > b.sigla ? 1 : -1
      }).sort((a, b) => {
        if (a.administrativo === b.administrativo) {
          return 0
        }

        return !a.administrativo && b.administrativo ? -1 : 1
      }).map(function (producao) {
        return {
          id: producao.empresa_id + String(producao.id).padStart(5, '0'),
          producao_id: producao.id,
          nome: producao.sigla,
          empresa_id: producao.empresa_id,
          svi: producao.usu_codsvi,
          endereco_id: producao.endereco_id,
        }
      })
    return producoesLista.concat(producoes)
  },
}
