<template>
  <v-autocomplete
    v-model="model"
    :items="options"
    :loading="loading"
    item-text="label"
    item-value="id"
    :disabled="disabled || readonly || loading"
    :rules="rule"
    :multiple="multiple"
    dense
    outlined
    hide-no-data
    persistent-placeholder
    :label="computedLabel"
    :return-object="returnObject">
  </v-autocomplete>
</template>
<script type="text/javascript">

import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    nome: {
      type: [String, Number],
      default: 'default',
    },
    mostraTodosLabel: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: String,
      default: '',
    },
    producao: {
      type: String,
      default: '',
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Bem Patrimonial',
    },
  },
  data: (instance) => ({
    rule: [(v) => !instance.obrigatorio || !!v || 'Selecione o Bem Patrimonial'],
    options: [],
    loading: false,
  }),

  mounted () {
    this.updateList()
  },
  computed: {
    computedLabel () {
      return this.obrigatorio ? `${this.label} *` : this.label
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  watch: {
    producao () {
      this.updateList()
    },
  },
  methods: {
    updateList () {
      if (!this.all && (!this.empresa || !this.producao) ){
        this.model = null
        this.options = []
        return
      }

      const params = !this.all ? {
        empresa: this.empresa,
        producao: this.producao,
      } : {}

      this.loading = true

      ApiService.cancel('bemPatrimonial.' + this.nome).get('/sapiens/bem-patrimonial/select-input', '?' + this.$stringFormat.queryParams(params)).then((res) => {
        this.options = res.data.data
      }).catch((error) => {
        this.loading = false
        console.error(error)
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
