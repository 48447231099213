<template>
  <v-autocomplete
    v-model="model"
    :clearable="clearable"
    :label="computedLabel"
    :items="empresasOptions"
    item-text="nome"
    item-value="id"
    dense
    outlined
    :multiple="multiple"
    :rules="empresaRule"
    :return-object="returnObject"
    :disabled="disabled || readonly"
    :hide-details="hideDetails"
    :persistent-placeholder="persistentPlaceholder"
  >
    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0" style="max-width: 90%" class="d-inline-block text-truncate">
        {{ item.nome }}
      </span>
      <span v-if="index === 1" class="ml-1 grey--text caption">
        +{{ model.length - 1 }} outra(s)...
      </span>
    </template>
  </v-autocomplete>
</template>

<script type="text/javascript">
export default {
  props: {
    value: {
      type: [String, Object, Array],
      default: '',
    },
    mostraTodosLabel: {
      type: Boolean,
      default: false,
    },
    svi: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data: (instance) => ({
    empresaRule: [(v) => !instance.obrigatorio || !!v || 'Selecione a Empresa'],
    empresasOptions: [{ id: '', nome: 'Selecione uma Empresa' }],
  }),
  computed: {
    computedLabel () {
      const label = this.label ?? (this.svi ? 'SVI' : 'Empresa')
      return this.obrigatorio ? `${label} *` : label
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        if (this.multiple) {
          if (val.length === 0) {
            val = ['']
          }

          const getIdByValue = this.getIdByValue
          let firstIsAll = null

          val = val.reverse().filter(function (item) {
            if (firstIsAll === null) {
              firstIsAll = getIdByValue(item) === ''
              return true
            }

            return !firstIsAll && getIdByValue(item) > 0
          })
        }

        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  mounted () {
    this.getEmpresas()
  },
  methods: {
    getIdByValue (value) {
      return typeof value === 'object' ? value.id : value
    },
    getEmpresas () {
      if (this.mostraTodosLabel && this.value === null) {
        this.model = ''
      }

      if (this.svi) {
        this.empresasOptions = this.$empresas.listSvis(this.mostraTodosLabel)
        return
      }

      this.empresasOptions = this.$empresas.list(this.mostraTodosLabel)
    },
  },
}
</script>
