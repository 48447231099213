import { getUser } from '@/core/services/storage.service'

// action types
export const UPDATE_PERSONAL_INFO = 'updateUserPersonalInfo'
export const UPDATE_ACCOUNT_INFO = 'updateUserAccountInfo'

// mutation types
export const SET_PERSONAL_INFO = 'setPersonalInfo'
export const SET_ACCOUNT_INFO = 'setAccountInfo'

const state = {
  user_personal_info: {
    photo: '/media/imgs/avatar.jpg',
    name: '',
    surname: '',
    company_name: '',
    job: 'Usuário',
    email: '',
    phone: '',
    company_site: '',
    ...getUser(),
  },
  user_account_info: {
    username: '',
    email: '',
    language: '',
    time_zone: '',
    communication: {
      email: true,
      sms: true,
      phone: false,
    },
    verification: false,
  },
}

const getters = {
  currentUserPersonalInfo (state) {
    return state.user_personal_info
  },

  currentUserAccountInfo (state) {
    return state.user_account_info
  },
}

const actions = {
  [UPDATE_PERSONAL_INFO] (context, payload) {
    context.commit(SET_PERSONAL_INFO, payload)
  },
  [UPDATE_ACCOUNT_INFO] (context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload)
  },
}

const mutations = {
  [SET_PERSONAL_INFO] (state, userPersonalInfo) {
    state.user_personal_info = {
      ...state.user_personal_info,
      name: userPersonalInfo.name,
      surname: '',
      email: userPersonalInfo.email,
    }
  },
  [SET_ACCOUNT_INFO] (state, userAccountInfo) {
    state.user_account_info = userAccountInfo
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
