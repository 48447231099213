<template>
  <bloco-informativo texto-informativo="Campo aceita cola de valores com geolocalização, como '-23.00000, -47.00000'">
    <div v-if="!showEdit">
      <v-text-field
          ref="input"
          dense
          outlined
          persistent-placeholder
          :label="computedLabel"
          v-model="model"
          readonly
          :rules="rules"
          @paste="colar"
          @keydown.delete="deletar"
          :append-outer-icon="!readonly && editable? 'mdi-pencil' : null"
          @click:append-outer="editar"
      />
      <span class="link-google"><a :href="value.origemUrlGoogle" target="_blank" v-if="value.origemUrlGoogle">Abrir no Google Maps</a></span>
    </div>
    <v-row v-if="showEdit">
      <v-col cols="6">
        <v-text-field
            ref="input"
            dense
            outlined
            persistent-placeholder
            label="Latitude"
            v-model="itemEdicao.latitude"
            :readonly="readonly"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
            ref="input"
            dense
            outlined
            persistent-placeholder
            label="Longitude"
            v-model="itemEdicao.longitude"
            :readonly="readonly"
            :append-outer-icon="editable? 'mdi-check' : null"
            @click:append-outer="confirmaEdicao"
        />
      </v-col>
    </v-row>
  </bloco-informativo>
</template>
<style>
  .v-select__selections span {
    margin: 2px 4px!important;
  }
</style>
<script type="text/javascript">

export default {
  props: {
    value: {
      type: [Object],
      default: () => ({
        origemUrlGoogle: '',
        latitude: '',
        longitude: '',
      }),
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Coordenadas',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    coordenadasErradas: false,
    urlMapsErradas: false,
    showEdit: false,
    itemEdicao: {
      latitude: null,
      longitude: null,
    },
  }),
  computed: {
    rules () {
      return [
        (v) => !this.obrigatorio || !!v || 'Selecione a Cidade',
        (v) => !this.coordenadasErradas || 'Coordenada informada errada',
        (v) => !this.urlMapsErradas || 'Url do Maps informada de forma errada',
      ]
    },
    computedLabel () {
      return this.obrigatorio ? `${this.label} *` : this.label
    },
    model: {
      get () {
        if (!this.value.latitude || !this.value.latitude) {
          return ''
        }

        return this.value.latitude + ', ' + this.value.longitude
      },
      set (val) {
        this.value = val
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  mounted () {
  },

  methods: {
    setDefault () {
      this.model = {
        origemUrlGoogle: '',
        latitude: '',
        longitude: '',
      }
    },
    colar (event) {
      const clipboardData = event.clipboardData || window.clipboardData
      const items = clipboardData.items

      for (let i = 0; i < items.length; i++) {
        if (items[i].type === 'text/plain') {
          items[i].getAsString((string) => {
            this.estruturaDados(string)
          })
        }
      }
    },
    editar () {
      this.showEdit = true
      this.itemEdicao = this.value
    },
    confirmaEdicao () {
      this.showEdit = false

      if (!this.itemEdicao.latitude || !this.itemEdicao.longitude) {
        return
      }

      this.model = {
        origemUrlGoogle: 'https://www.google.com/maps/place/' + this.itemEdicao.latitude + ',' +
            this.itemEdicao.longitude + '/@' + this.itemEdicao.latitude + ',' + this.itemEdicao.longitude + ',17z/',
        latitude: this.itemEdicao.latitude,
        longitude: this.itemEdicao.longitude,
      }
    },
    deletar () {
      if (!this.removable) {
        return
      }

      this.setDefault()
    },
    estruturaDados (colaString) {
      this.$refs.input.validate()
      this.coordenadasErradas = false
      this.urlMapsErradas = false

      if (colaString.split(',').length > 1) {
        return this.formataCoordenadas(colaString)
      }
    },
    formataCoordenadas (coordenadas) {
      const cords = coordenadas.split(',')

      if (cords.length > 2) {
        this.coordenadasErradas = true
        this.setDefault()
        return
      }

      if (isNaN(cords[0]) || isNaN(cords[1])) {
        this.coordenadasErradas = true
        this.setDefault()
        return
      }

      this.model = {
        origemUrlGoogle: 'https://www.google.com/maps/place/' + coordenadas + '/@' + cords[0].trim() + ',' + cords[1].trim() + ',17z/',
        latitude: cords[0].trim(),
        longitude: cords[1].trim(),
      }
    },
  },
}
</script>

<style>
.link-google {
  display: block;
  margin-top: -1.25rem;
  height: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  position: absolute;
  z-index: 100;
}
</style>
