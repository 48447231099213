<template>
  <v-autocomplete
    v-model="model"
    :label="computedLabel"
    :items="options"
    item-text="nome"
    item-value="id"
    dense
    outlined
    :multiple="multiple"
    :rules="computedRule"
    :return-object="returnObject"
    :loading="loading"
    :disabled="disabled || loading"
  >
    <template v-slot:selection="{ item, index }">
      <div v-if="index === 0">
        <span>{{ item.nome }}</span>
      </div>
      <span v-if="index === 1" class="ml-1 grey--text caption">
        +{{ model.length - 1 }} outra(s)...
      </span>
    </template>
  </v-autocomplete>
</template>
<style>
  .v-select__selections span {
    margin: 2px 4px!important;
  }
</style>
<script type="text/javascript">

import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: [String, Object],
      default: '',
    },
    mostraTodosLabel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: [Object, String],
      default: '',
    },
    producao: {
      type: [Object, String],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    svi: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    areaExterna: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Talhão(ões)',
    },
  },
  data: () => ({
    options: [],
    loading: false,
  }),
  computed: {
    computedLabel () {
      return this.obrigatorio ? `${this.label} *` : this.label
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        if (this.multiple) {
          let firstIsAll = null
          const getIdByValue = this.getIdByValue

          val = val.reverse().filter(function (item) {
            if (firstIsAll === null) {
              firstIsAll = getIdByValue(item) === ''
              return true
            }

            return !firstIsAll && getIdByValue(item) !== ''
          })
        }

        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
    computedRule: {
      get () {
        return [(v) => !this.obrigatorio || !!v || 'Selecione a Produção'].concat(this.rules)
      },
      set (val) {},
    },
  },
  watch: {
    producao () {
      this.getTalhoes()
    },
  },

  mounted () {
    this.getTalhoes()
  },

  methods: {
    getIdByValue (value) {
      return typeof value === 'object' && value !== null ? value.id : value || ''
    },
    getDefaultValue () {
      if (!this.multiple) {
        return this.mostraTodosLabel ? '' : null
      }

      return this.mostraTodosLabel ? [''] : []
    },
    getTalhoes () {
      this.loading = true
      const talhoesOptions = []

      if (this.mostraTodosLabel) {
        talhoesOptions.push({ id: '', nome: 'Todos', producao: '' })
      }

      const producoes = [].concat(this.producao)

      if (producoes.length === 0 || this.getIdByValue(producoes[0]) === '') {
        this.loading = false
        this.options = talhoesOptions
        return
      }

      const getIdByValue = this.getIdByValue

      if (producoes.length > 1) {
        producoes.map(function (producao) {
          if (typeof producao === 'object') {
            talhoesOptions.push({ id: 'header-' + getIdByValue(producao), header: producao.nome, producao: producao.nome })
          }
        })
      }

      if (this.areaExterna) {
        producoes.map(function (producao) {
          talhoesOptions.push({ id: 'ae-' + getIdByValue(producao), nome: 'Área externa', producao: producao.nome })
        })
      }

      const empresaId = this.getIdByValue(this.empresa)
      const params = {
        producoes: producoes.map(function (producao) {
          if (typeof producao === 'object') {
            return {
              id: producao.id ?? 0,
              empresa: producao.empresa_id ?? 0,
              svi: producao.svi ?? 0,
            }
          }

          return {
            id: producao,
            empresa: empresaId,
            svi: '',
          }
        }),
      }

      ApiService
        .query('/talhao-select', { params })
        .then((res) => {
          talhoesOptions.push(...res.data.data)

          this.options = talhoesOptions.sort((a, b) => {
            if (a.producao < b.producao) {
              return -1
            }

            return a.producao > b.producao
          })

          if (!this.multiple && this.options.filter((t) => t.id === this.model).length === 0) {
            this.model = this.getDefaultValue()
          }

          this.loading = false
        }).catch(() => {
          this.loading = false
        })
    },
  },
}
</script>
