import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#395764',
        secondary: '#e8ecfa',
        accent: '#5d78ff',
        error: '#fd397a',
        info: '#395764',
        success: '#0abb87',
        warning: '#ffb822',
      },
    },
  },
})
