
export const OPEN_FILTER = 'filter_open'
export const CLOSE_FILTER = 'filter_close'

export const SET_OPEN = 'set_open'

const state = {
  filter: {
    open: false,
  },
}

const getters = {
  isOpenFilter (state) {
    return state.filter.open
  },
}

const actions = {
  [OPEN_FILTER] (context) {
    context.commit(SET_OPEN, true)
  },
  [CLOSE_FILTER] (context) {
    context.commit(SET_OPEN, false)
  },
}

const mutations = {
  [SET_OPEN] (state, open) {
    state.filter.open = open
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
