<template>
  <span
    class="label label-lg label-inline"
    :class="labelColor"
  >
    {{ text }}
  </span>
</template>
<script type="text/javascript">
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    labelColor () {
      return `label-light-${this.color}`
    },
  },
}
</script>
