<template>
  <v-autocomplete
    v-model="model"
    :items="options"
    :loading="loading"
    item-text="label"
    item-value="id"
    :disabled="disabled || loading"
    :rules="computedRule"
    dense
    outlined
    hide-no-data
    persistent-placeholder
    :label="computedLabel"
    auto-select-first
    :return-object="returnObject">
    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0" style="max-width: 90%" :class="{
        'd-inline-block text-truncate': true,
        'w-75': (model.length > 1),
      }">
        {{ item.label }}
      </span>
      <span v-if="index === 1" class="ml-1 grey--text caption">
        +{{ model.length - 1 }} outra(s)...
      </span>
    </template>
    <template v-slot:prepend>
      <v-slide-x-transition
          mode="out-in" v-if="$slots.icon"
      >
        <slot name="icon"></slot>
      </v-slide-x-transition>
    </template>
  </v-autocomplete>
</template>
<script type="text/javascript">
import axios from 'axios'

import ApiService from '@/core/services/api.service'
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    empresa: {
      type: String,
      default: '',
    },
    producao: {
      type: String,
      default: '',
    },
    fornecedor: {
      type: String,
      default: '',
    },
    dataEmissao: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default () {
        return []
      },
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Nº RPA',
    },
  },
  data: () => ({
    options: [],
    loading: false,
    apiFetch: null,
  }),

  mounted () {
    this.getData()
  },
  computed: {
    computedLabel () {
      return this.obrigatorio ? `${this.label} *` : this.label
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
    computedRule: {
      get () {
        return [(v) => !this.obrigatorio || !!v || 'Selecione o RPA'].concat(this.rules)
      },
      set (val) {},
    },
  },
  watch: {
    fornecedor () {
      this.getData()
    },
  },
  methods: {
    getIdByValue (value) {
      return typeof value === 'object' && value !== null ? value.id : value
    },
    getData () {
      if (this.disabled) {
        return
      }

      this.loading = true
      this.options = []

      if (!this.empresa || !this.producao || !this.fornecedor) {
        this.model = ''
        this.loading = false
        return
      }

      this.cancel()

      this.apiFetch = axios.CancelToken.source()

      this.buscaDados({
        empresa: this.empresa,
        producao: this.producao,
        fornecedor: this.fornecedor,
        dataEmissao: this.dataEmissao,
      })
    },

    buscaDados (params) {
      ApiService.get(
        '/compras/rpa',
        '?' + this.$stringFormat.queryParams(params), {
          cancelToken: this.apiFetch.token,
        })
        .then((res) => {
          this.options.push(...res.data.data)
        }).catch(() => {
          this.loading = false
        }).finally(() => {
          this.loading = false
        })
    },

    cancel () {
      if (this.apiFetch) {
        this.apiFetch.cancel()
      }
    },
  },
}
</script>
