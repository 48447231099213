<template>
  <div>
    <v-dialog
        v-model="isOpenFilter"
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        v-if="isMobile"
    >
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="closeFilter"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- <v-toolbar-title>Settings</v-toolbar-title> -->
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="p-5">
          <v-row>
            <slot></slot>
            <slot name="outros"></slot>
            <v-col class="col-12">
              <v-btn
                  outlined
                  block
                  color="white"
                  class="v-btn--height-fix primary"
                  @click="search"
              >
                <v-slide-x-reverse-transition mode="out-in">
                  <span :key="`text-${hasChange}`" class="font-weight-bold my-2 mr-3">{{hasChange? 'ATUALIZAR': 'ATUALIZADO'}}</span>
                </v-slide-x-reverse-transition>
                <v-slide-x-reverse-transition mode="out-in">
                  <v-icon class="filter-icon"
                          :key="`icon-${hasChange}`"
                          v-text="hasChange ? 'mdi-cached' : 'mdi-check'"
                  ></v-icon>
                </v-slide-x-reverse-transition>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card class="p-6 mb-3 d-none d-lg-block" v-if="!isMobile">
      <v-card-text class="p-0">
        <v-row>
          <slot></slot>
          <div class="col-12 col-lg-2" v-if="$slots.outros">
            <v-menu
                :close-on-content-click="false"
                :nudge-width="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    outlined
                    block
                    color="white"
                    class="v-btn--height-fix primary"
                    v-bind="attrs"
                    v-on="on"
                >
                  Mais Filtros
                </v-btn>
              </template>
              <v-card class="pt-4">
                <slot name="outros"></slot>
              </v-card>
            </v-menu>
          </div>
          <v-col class="col-12 col-md-3 col-lg-2 ml-auto">
            <v-btn
                outlined
                block
                color="white"
                class="v-btn--height-fix primary"
                :loading="loading"
                @click="search"
            >
              <v-slide-x-reverse-transition
                  mode="out-in"
              >
                <span
                    :key="`text-${hasChange}`" class="font-weight-bold my-2 mr-3">{{hasChange? 'ATUALIZAR': 'ATUALIZADO'}}</span>
              </v-slide-x-reverse-transition>
              <v-slide-x-reverse-transition
                  mode="out-in"
              >
                <v-icon class="filter-icon"
                        :key="`icon-${hasChange}`"
                    v-text="hasChange ? 'mdi-cached' : 'mdi-check'"
                ></v-icon>
              </v-slide-x-reverse-transition>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { CLOSE_FILTER } from '@/core/services/store/filter.module'
import KTUtil from '@/assets/js/components/util'

export default {
  props: {
    hasChange: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isMobile: false,
    }
  },
  mounted () {
    this.isMobile = KTUtil.isMobileDevice()
  },
  computed: {
    ...mapGetters(['isOpenFilter']),
  },
  methods: {
    closeFilter () {
      this.$store.dispatch(CLOSE_FILTER)
    },
    search () {
      this.$emit('search')
      this.$store.dispatch(CLOSE_FILTER)
    },
  },
}
</script>
