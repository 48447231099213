import ApiService from '@/core/services/api.service'
import { getProdutos, saveProdutos } from '@/core/services/storage.service'
import { stringFormat } from '@/core/functions/string-format'

// mutation types
export const UPDATE_PRODUTOS = 'updateProdutos'
export const SET_PRODUTOS = 'setProdutos'

const state = {
  options: getProdutos() || {},
}

const getters = {
  produtos (state) {
    return state.options
  },
  produtosPorProducao: (state) => (empresa, producao) => {
    if (!state.options[empresa]) {
      return []
    }

    if (!state.options[empresa][producao]) {
      return []
    }

    return state.options[empresa][producao]
  },
}

const actions = {
  [UPDATE_PRODUTOS] (context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        !params.buscaSolicitacaoCompra ? '/sapiens/produto/autocomplete' : '/sapiens/produto/compras',
        '?' + stringFormat.queryParams(params))
        .then(({ data }) => {
          context.commit(SET_PRODUTOS, params, data.data)
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.errors)
        })
    })
  },
}

const mutations = {
  [SET_PRODUTOS] (state, params, produtos) {
    state.produtos[params.empresa][params.producao] = produtos
    saveProdutos(state.produtos)
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
