<template>
  <v-autocomplete
    v-model="model"
    :items="options"
    :loading="loading"
    item-text="name"
    item-value="id"
    :disabled="disabled || loading"
    :rules="computedRule"
    dense
    outlined
    hide-no-data
    persistent-placeholder
    :label="computedLabel"
    :small-chips="multiple"
    :chips="multiple"
    :deletable-chips="multiple"
    :multiple="multiple"
    :return-object="returnObject">
    <template v-slot:prepend>
      <v-slide-x-transition
          mode="out-in" v-if="$slots.icon"
      >
        <slot name="icon"></slot>
      </v-slide-x-transition>
    </template>
  </v-autocomplete>
</template>
<script type="text/javascript">

import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      default: 'Contratação',
    },
    mostraTodosLabel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: [String, Object],
      default: '',
    },
    producao: {
      type: [String, Object],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    buscaUsuarioServico: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    options: [],
    loading: false,
  }),

  mounted () {
    this.getUsuarios()
  },
  computed: {
    computedLabel () {
      return this.obrigatorio ? `${this.label} *` : this.label
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        if (this.multiple) {
          let firstIsAll = null
          const getIdByValue = this.getIdByValue

          val = val.reverse().filter(function (item) {
            if (firstIsAll === null) {
              firstIsAll = getIdByValue(item) === ''
              return true
            }

            return !firstIsAll && getIdByValue(item) !== ''
          })
        }

        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
    computedRule: {
      get () {
        return [(v) => !this.obrigatorio || !!v || 'Selecione um usuário'].concat(this.rules)
      },
      set (val) {},
    },
  },
  watch: {
    producao () {
      this.getUsuarios()
    },
  },
  methods: {
    getIdByValue (value) {
      return typeof value === 'object' && value !== null ? value.id : value
    },
    getUsuarios () {
      this.loading = true
      const usuariosOptions = []

      if (this.mostraTodosLabel) {
        usuariosOptions.push({ id: '', label: 'Todos' })
      }

      const empresas = [].concat(this.empresa)

      if (empresas.length === 0 || !this.getIdByValue(empresas[0])) {
        this.model = ''
        this.loading = false
        this.options = usuariosOptions
        return
      }

      const producoes = [].concat(this.producao)

      if (producoes.length === 0 || !this.getIdByValue(producoes[0])) {
        this.model = ''
        this.loading = false
        this.options = usuariosOptions
        return
      }

      if (empresas.length > 1 || producoes.length > 1) {
        this.loading = false
        this.options = usuariosOptions
        throw new Error('Multi select de empresas e produção não aplicados')
      }

      const params = {
        empresa: this.getIdByValue(empresas[0]),
        producao: this.getIdByValue(producoes[0]),
      }

      ApiService.get(
        this.buscaUsuarioServico = '/sapiens/usuario-servicos/autocomplete',
        '?' + this.$stringFormat.queryParams(params)).then((res) => {
        usuariosOptions.push(...res.data.data)
        this.options = usuariosOptions
      }).catch((error) => {
        this.loading = false
        console.error(error)
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
