export const labelFormat = {
  // Colors helpers
  getColor: (boolField) => {
    return !boolField ? 'danger' : 'success'
  },
  getColorWithAnotherOptions: (boolField) => {
    switch (boolField) {
      case 0:
        return 'danger'
      case 1:
        return 'warning'
      case 2:
        return 'success'
    }
  },

  // Messages helpers
  getMessage: (field) => {
    return !field ? 'Não' : 'Sim'
  },
  getStatusMessage: (status) => {
    return !status ? 'Inativo' : 'Ativo'
  },
}
