// Keenthemes plugins
import KTUtil from '@/assets/js/components/util.js'
import KTCard from '@/assets/js/components/card.js'
import KTCookie from '@/assets/js/components/cookie.js'
import KTDialog from '@/assets/js/components/dialog.js'
import KTHeader from '@/assets/js/components/header.js'
import KTImageInput from '@/assets/js/components/image-input.js'
import KTMenu from '@/assets/js/components/menu.js'
import KTOffcanvas from '@/assets/js/components/offcanvas.js'
import KTScrolltop from '@/assets/js/components/scrolltop.js'
import KTToggle from '@/assets/js/components/toggle.js'
import KTWizard from '@/assets/js/components/wizard.js'
import KTLayoutAside from '@/assets/js/layout/base/aside.js'
import KTLayoutAsideMenu from '@/assets/js/layout/base/aside-menu.js'
import KTLayoutAsideToggle from '@/assets/js/layout/base/aside-toggle.js'
import KTLayoutBrand from '@/assets/js/layout/base/brand.js'
import KTLayoutContent from '@/assets/js/layout/base/content.js'
import KTLayoutFooter from '@/assets/js/layout/base/footer.js'
import KTLayoutHeader from '@/assets/js/layout/base/header.js'
import KTLayoutHeaderMenu from '@/assets/js/layout/base/header-menu.js'
import KTLayoutHeaderTopbar from '@/assets/js/layout/base/header-topbar.js'
import KTLayoutStickyCard from '@/assets/js/layout/base/sticky-card.js'
import KTLayoutStretchedCard from '@/assets/js/layout/base/stretched-card.js'
import KTLayoutSubheader from '@/assets/js/layout/base/subheader.js'
import KTLayoutChat from '@/assets/js/layout/extended/chat.js'
import KTLayoutQuickActions from '@/assets/js/layout/extended/quick-actions.js'
import KTLayoutQuickNotifications from '@/assets/js/layout/extended/quick-notifications.js'
import KTLayoutQuickPanel from '@/assets/js/layout/extended/quick-panel.js'
import KTLayoutQuickSearch from '@/assets/js/layout/extended/quick-search.js'
import KTLayoutScrolltop from '@/assets/js/layout/extended/scrolltop.js'
import KTLayoutSearch from '@/assets/js/layout/extended/search.js'

window.KTUtil = KTUtil
window.KTCard = KTCard
window.KTCookie = KTCookie
window.KTDialog = KTDialog
window.KTHeader = KTHeader
window.KTImageInput = KTImageInput
window.KTMenu = KTMenu
window.KTOffcanvas = KTOffcanvas
window.KTScrolltop = KTScrolltop
window.KTToggle = KTToggle
window.KTWizard = KTWizard
window.KTLayoutAside = KTLayoutAside
window.KTLayoutAsideMenu = KTLayoutAsideMenu
window.KTLayoutAsideToggle = KTLayoutAsideToggle
window.KTLayoutBrand = KTLayoutBrand
window.KTLayoutContent = KTLayoutContent
window.KTLayoutFooter = KTLayoutFooter
window.KTLayoutHeader = KTLayoutHeader
window.KTLayoutHeaderMenu = KTLayoutHeaderMenu
window.KTLayoutHeaderTopbar = KTLayoutHeaderTopbar
window.KTLayoutStickyCard = KTLayoutStickyCard
window.KTLayoutStretchedCard = KTLayoutStretchedCard
window.KTLayoutSubheader = KTLayoutSubheader
window.KTLayoutChat = KTLayoutChat
window.KTLayoutQuickActions = KTLayoutQuickActions
window.KTLayoutQuickNotifications = KTLayoutQuickNotifications
window.KTLayoutQuickPanel = KTLayoutQuickPanel
window.KTLayoutQuickSearch = KTLayoutQuickSearch
window.KTLayoutScrolltop = KTLayoutScrolltop
window.KTLayoutSearch = KTLayoutSearch
