<template>
  <div
    class="alert"
    :class="alertColor"
  >
    <strong>{{ text }}</strong>
  </div>
</template>

<script type="text/javascript">
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    alertColor () {
      return `alert-${this.color}`
    },
  },
}
</script>
