import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{attrs:{"items":_vm.produtosOptions,"loading":_vm.loading,"item-text":"label","item-value":"id","disabled":_vm.disabled || _vm.readonly,"rules":_vm.computedRule,"dense":"","outlined":"","hide-no-data":"","persistent-placeholder":"","label":_vm.computedLabel,"small-chips":_vm.multiple,"chips":_vm.multiple,"deletable-chips":_vm.multiple,"multiple":_vm.multiple,"search-input":_vm.search,"no-filter":"","auto-select-first":"","return-object":_vm.returnObject},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{class:{
      'd-inline-block text-truncate': true,
      'w-75': (_vm.model.length > 1),
    },staticStyle:{"max-width":"90%"}},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"ml-1 grey--text caption"},[_vm._v(" +"+_vm._s(_vm.model.length - 1)+" outra(s)... ")]):_vm._e()]}},{key:"prepend",fn:function(){return [(_vm.$slots.icon)?_c(VSlideXTransition,{attrs:{"mode":"out-in"}},[_vm._t("icon")],2):_vm._e()]},proxy:true},{key:"append-item",fn:function(){return [(_vm.page < _vm.ultimaPagina && (_vm.page > 1 || !_vm.loading))?_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],staticClass:"pl-3 pt-5"},[_vm._v(" Carregando mais itens ")]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})}
var staticRenderFns = []

export { render, staticRenderFns }