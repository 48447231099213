<template>
  <v-autocomplete
    v-model="model"
    :items="fornecedorOptions"
    :loading="loading"
    item-text="label"
    item-value="id"
    :disabled="disabled || loading"
    :rules="rule"
    @update:search-input="findByText"
    auto-select-first
    dense
    outlined
    hide-no-data
    persistent-placeholder
    :label="computedLabel"
  />
</template>
<script type="text/javascript">

import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    mostraTodosLabel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: String,
      default: '',
    },
    producao: {
      type: String,
      default: '',
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textOption: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Nome do Fornecedor',
    },
  },
  data: (instance) => ({
    rule: [(v) => !instance.obrigatorio || !!v || 'Selecione o Fornecedor'],
    options: [],
    termoBusca: null,
    loading: false,
  }),
  mounted () {
    this.getFornecedores()
  },
  computed: {
    computedLabel () {
      return this.obrigatorio ? `${this.label} *` : this.label
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
    fornecedorOptions () {
      let options = [].concat(this.options)

      if (this.textOption && this.termoBusca) {
        options = [{
          label: this.termoBusca,
          id: 'tx-' + this.termoBusca,
        }].concat(options)
      }

      return options
    },
  },
  watch: {
    producao () {
      this.getFornecedores()
    },
  },
  methods: {
    findByText (termo) {
      this.termoBusca = termo
    },
    getFornecedores () {
      if (!this.producao) {
        this.model = ''
        this.options = []
        return
      }

      const params = {
        empresa: this.empresa,
        producao: this.producao,
        busca: '',
      }

      this.loading = true

      ApiService.get('/fornecedores', '?' + this.$stringFormat.queryParams(params)).then((res) => {
        this.options = res.data.data

        if (this.value && this.options.filter((option) => option.id === this.value).length === 0) {
          this.termoBusca = this.value.replace('tx-', '')
        }
      }).catch(() => {
        this.loading = false
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
