import ApiService from '@/core/services/api.service'
import { getUser, getToken, saveToken, saveUser, destroyAll } from '@/core/services/storage.service'
import { UPDATE_PERSONAL_INFO } from '@/core/services/store/profile.module'

// action types
export const VERIFY_AUTH = 'verifyAuth'
export const LOGIN = 'login'
export const LOGOUT = 'logout'

// mutation types
export const PURGE_AUTH = 'logOut'
export const SET_AUTH = 'setUser'
export const SET_ERROR = 'setError'

const state = {
  errors: null,
  user: getUser(),
  isAuthenticated: !!getToken(),
}

const getters = {
  currentUser (state) {
    return state.user
  },
  isAuthenticated (state) {
    return state.isAuthenticated
  },
}

const actions = {
  [LOGIN] (context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('auth/login', credentials)
        .then((response) => response.data)
        .then(({ data }) => {
          context.commit(SET_AUTH, data)
          context.dispatch(UPDATE_PERSONAL_INFO, data.user)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response.data.errors)
        })
    })
  },
  [LOGOUT] (context) {
    context.commit(PURGE_AUTH)
  },
  [VERIFY_AUTH] (context) {
    if (getToken()) {
      ApiService.setHeader()

      ApiService.get('auth/me')
        .then((response) => response.data)
        .then(({ data }) => {
          context.commit(SET_AUTH, data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    } else {
      context.commit(PURGE_AUTH)
    }
  },
}

const mutations = {
  [SET_ERROR] (state, error) {
    state.errors = error
  },
  [SET_AUTH] (state, data) {
    state.isAuthenticated = true
    state.token = data.token
    state.user = data.user
    state.errors = {}
    saveToken(state.token)
    saveUser(state.user)
    ApiService.setHeader()
  },
  [PURGE_AUTH] (state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    destroyAll()
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
