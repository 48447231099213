<template>
  <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="600"
  >
    <template v-slot:activator="{}">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              :color="!disabled? 'primary': 'secondary'"
              dark
              v-bind="attrs"
              class="mr-3"
              v-on="!disabled ? on: null"
              title="Exportar"
          >
            <v-icon dark :color="!disabled? 'white' : 'black'">mdi-printer</v-icon>
          </v-btn>
        </template>
        <custom-snackbar
            ref="snackbar"
        />
        <v-list>
          <v-list-item @click="exportar(item.tipo, item.acao)" v-for="(item, i) in listaAcoes" :key="i">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-dialog>
</template>

<script type="text/javascript">
import ApiService from '@/core/services/api.service'

export default {
  props: {
    filtro: Object,
    urlPdf: String,
    relatorioExcel: String,
    exports: {
      type: Array,
      default: () => [],
    },
    excel: {
      type: Boolean,
      default: false,
    },
    pdf: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    excelPdf: {
      type: Boolean,
      default: false,
    },
  },
  data: (instance) => ({
    dialog: false,
  }),
  computed: {
    listaAcoes: function () {
      var acoes = []

      if (this.pdf) {
        acoes.push({
          text: 'Exportar PDF',
          tipo: 'pdf',
          acao: this.urlPdf,
        })
      }

      if (this.excelPdf) {
        acoes.push({
          text: 'Exportar PDF',
          tipo: 'excel-pdf',
          acao: this.relatorioExcel,
        })
      }

      if (this.excel) {
        acoes.push({
          text: 'Exportar Excel',
          tipo: 'excel',
          acao: this.relatorioExcel,
        })
      }

      acoes = acoes.concat(this.exports)

      return acoes
    },
  },
  methods: {
    exportar (tipo, acao) {
      if (tipo === 'pdf') {
        this.exportPDF(acao)
      }

      if (tipo === 'excel-pdf') {
        this.exportExcelPdf(acao)
      }

      if (tipo === 'excel') {
        this.exportExcel(acao)
      }
    },
    exportPDF (url) {
      this.$refs.snackbar.show('Gerando Relatório', 'Seu PDF será gerado e aberto em nova aba para você poder salvar ou imprimir', 'default', -1, true)

      ApiService
        .get(url, '?' + this.$stringFormat.queryParamsRecursive(this.filtro))
        .then((res) => {
          this.$refs.snackbar.show('Relatório Gerado', '', 'success', 3000, false)
          const newWindow = window.open()
          newWindow.document.write(res.data)
        })
        .catch(() => {
          this.$refs.snackbar.show('Relatório Não pode ser gerado', 'Contate a equipe de TI e passe os parâmetros para ser simulado!', 'danger', 10000, false)
        })
    },
    exportExcel (acao) {
      this.$refs.snackbar.show('Gerando Relatório', 'Seu Excel será gerado e baixado automaticamente', 'default', -1, true)

      ApiService
        .post('/relatorios/exportar', {
          relatorio: acao,
          parametros: JSON.stringify(this.filtro),
        })
        .then((res) => {
          if (res.data.data.arquivo === '') {
            throw new Error('Arquivo inexistente')
          }

          this.$refs.snackbar.show('Arquivo gerado com sucesso', '', 'success', 3000, false)
          window.open(res.data.data.arquivo, '_blank')
        })
        .catch(() => {
          this.$refs.snackbar.show('Relatório não pode ser gerado', 'Não foi possível gerar o arquivo, tente novamente mais tarde.', 'danger', 3000, false)
        })
    },
    exportExcelPdf (acao) {
      this.$refs.snackbar.show('Gerando Relatório', 'Seu PDF será gerado e aberto em nova aba para você poder salvar ou imprimir', 'default', -1, true)

      ApiService
        .post('/relatorios/exportar-excel-pdf', {
          relatorio: acao,
          parametros: JSON.stringify(this.filtro),
        })
        .then((res) => {
          if (res.data.data.arquivo === '') {
            throw new Error('Arquivo inexistente')
          }

          this.$refs.snackbar.show('Arquivo gerado com sucesso', '', 'success', 3000, false)
          window.open(res.data.data.arquivo, '_blank')
        })
        .catch(() => {
          this.$refs.snackbar.show('Relatório não pode ser gerado', 'Não foi possível gerar o arquivo, tente novamente mais tarde.', 'danger', 3000, false)
        })
    },
  },
}
</script>
