<template>
  <v-menu
      v-model="isOpen"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          clearable
          dense
          outlined
          readonly
          :label="label"
          append-icon="mdi-calendar"
          v-model="formattedDate"
          v-bind="attrs"
          :rules="rules"
          v-on="on"
          :disabled="disabled"
          :persistent-placeholder="persistentPlaceholder"
          @click:clear="selectedDate = null"
          @keyup.delete="clear"
      ></v-text-field>
    </template>
    <v-date-picker
        locale="pt-BR"
        v-model="selectedDate"
        @input="closeOnSelect"
        :max="max"
        :min="min"
        :range="range"
    ></v-date-picker>
  </v-menu>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: 'Data',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    max: {
      type: String,
    },
    min: {
      type: String,
    },
    rules: {
      type: String,
    },
    range: {
      type: Boolean,
      default: false,
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isOpen: false,
    }
  },
  computed: {
    formattedDate: {
      get () {
        if (this.range) {
          return this.selectedDate
            .sort()
            .map((date) => this.$stringFormat
              .formatDate(date))
            .filter((value, index, self) => {
              return self.indexOf(value) === index
            }).join(' ~ ')
        }

        return this.$stringFormat.formatDate(this.selectedDate)
      },
      set (val) {

      },
    },

    selectedDate: {
      get () {
        return this.value
      },

      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    closeOnSelect () {
      if (!this.range) {
        this.isOpen = false
      }
    },
    clear () {
      if (!this.clearable) {
        this.selectedDate = null
      }
    },
  },
}

</script>
