<template>
  <v-autocomplete
    v-model="model"
    :label="computedLabel"
    :items="options"
    item-text="nome"
    :item-value="itemValue"
    dense
    outlined
    :multiple="multiple"
    :rules="rules"
    :return-object="returnObject"
    :disabled="disabled || readonly"
    :loading="loading"
    :hide-details="hideDetails"
    :persistent-placeholder="persistentPlaceholder"
  >
    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0" style="max-width: 90%" class="d-inline-block text-truncate">
        {{ item.nome }}
      </span>
      <span v-if="index === 1" class="ml-1 grey--text caption">
        +{{ model.length - 1 }} outra(s)...
      </span>
    </template>
  </v-autocomplete>
</template>
<style>
  .v-select__selections span {
    margin: 2px 4px!important;
  }
</style>
<script type="text/javascript">

import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: [String, Object, Array],
      default: '',
    },
    mostraTodosLabel: {
      type: Boolean,
      default: false,
    },
    administrativo: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    estado: {
      type: [String, Object],
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Cidade',
    },
  },
  data: (instance) => ({
    rules: [(v) => !instance.obrigatorio || !!v || 'Selecione a Cidade'],
    options: [{ id: '', sigla: 'Selecione uma Produção' }],
    loading: false,
  }),
  computed: {
    computedLabel () {
      return this.obrigatorio ? `${this.label} *` : this.label
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        if (this.multiple) {
          if (val.length === 0) {
            val = ['']
          }

          let firstIsAll = null
          const getIdByValue = this.getIdByValue

          val = val.reverse().filter(function (item) {
            if (firstIsAll === null) {
              firstIsAll = getIdByValue(item) === ''
              return true
            }

            return !firstIsAll && getIdByValue(item) > 0
          })
        }

        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  watch: {
    async estado (currentValue) {
      await this.getData(currentValue)
      const cidadeId = this.getIdByValue(this.model)

      if (this.options.filter(function (cidade) {
        if (!cidadeId) {
          return false
        }

        return [].concat(cidadeId).includes(cidade.id)
      }).length === 0) {
        this.model = this.getDefaultValue()
      }
    },
  },

  mounted () {
    this.getData(this.estado)
  },

  methods: {
    getIdByValue (value) {
      return typeof value === 'object' && value !== null ? value.id : value
    },
    getDefaultValue () {
      return this.multiple ? [] : null
    },
    async getData (estado) {
      const getIdByValue = this.getIdByValue
      const estadoId = getIdByValue(estado)

      if (!estadoId) {
        this.options = this.mostraTodosLabel ? [{ id: '', nome: 'Todos' }] : []
        return
      }

      this.loading = true

      await ApiService
        .get('/fretes/ibge/estados/' + estadoId + '/municipios')
        .then((response) => {
          this.loading = false
          const options = this.mostraTodosLabel ? [{ id: '', nome: 'Todos' }] : []
          this.options = options.concat(response.data)
          this.model = this.options.filter((item) => item.id === this.getIdByValue(this.model))[0]
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>
