import Vue from 'vue'
import VueMask from 'v-mask'
import VueMoney from 'v-money'
import money from 'vuejs-money'
import VCurrencyField from 'v-currency-field'
import PerfectScrollbar from 'perfect-scrollbar'
import ClipboardJS from 'clipboard'
import VueMoment from 'vue-moment'
import moment from 'moment'

import App from '@/App.vue'
import router from '@/router'
import store from '@/core/services/store'
import ApiService from '@/core/services/api.service'
import { labelFormat } from '@/core/functions/label-format.js'
import { dataTableFilter, dataTableConfig } from '@/core/functions/data-table.js'
import { auth } from '@/core/functions/auth.js'
import { empresas } from '@/core/functions/empresas.js'
import { producoes } from '@/core/functions/producoes.js'
import { stringFormat } from '@/core/functions/string-format.js'
import { validations } from '@/core/functions/validations.js'
import { array } from '@/core/functions/array.js'
import { date } from '@/core/functions/date.js'
import Label from '@/core/components/Label.vue'
import Alert from '@/core/components/Alert.vue'
import DatePicker from '@/core/components/DatePicker.vue'
import Snackbar from '@/core/components/Snackbar.vue'
import BlocoInformativo from '@/core/components/BlocoInformativo.vue'
import EmpresasSelect from '@/view/components/EmpresasSelect.vue'
import ProducoesSelect from '@/view/components/ProducoesSelect.vue'
import UsuariosAgricolaSelect from '@/view/components/UsuariosAgricolaSelect'
import FornecedorSelect from '@/view/components/FornecedorSelect.vue'
import ProdutoSelect from '@/view/components/ProdutoSelect.vue'
import ServicoSelect from '@/view/components/ServicoSelect.vue'
import UsuarioSelect from '@/view/components/UsuarioSelect.vue'
import EnderecosSelect from '@/view/components/EnderecosSelect.vue'
import RPASelect from '@/view/components/RPASelect.vue'
import EstadoSelect from '@/view/components/EstadoSelect.vue'
import CidadeSelect from '@/view/components/CidadeSelect.vue'
import CoordenadasInput from '@/view/components/CoordenadasInput.vue'
import BemPatrimonialSelect from '@/view/components/BemPatrimonialSelect.vue'
import TalhoesSelect from '@/view/components/TalhoesSelect.vue'
import InputMoney from '@/view/components/InputMoney.vue'
import FiltroMobile from '@/core/components/FiltroMobile.vue'
import Exportar from '@/core/components/Exportar.vue'
import HeaderControl from '@/core/components/HeaderControl.vue'
import vuetify from '@/core/plugins/vuetify'
import '@/core/plugins/bootstrap-vue'
import '@/core/plugins/perfect-scrollbar'
import '@/core/plugins/highlight-js'
import '@/core/plugins/inline-svg'
import '@/core/plugins/apexcharts'
import '@/core/plugins/treeselect'
import '@/core/plugins/metronic'
import '@mdi/font/css/materialdesignicons.css'
import '@/core/plugins/formvalidation'
import '@/assets/js/directive/pushpin'

window.PerfectScrollbar = PerfectScrollbar
window.ClipboardJS = ClipboardJS

Vue.prototype.$moment = moment
Vue.config.productionTip = false
Vue.use(VueMask)
Vue.use(VueMoney)
Vue.use(money)
Vue.use(VCurrencyField)
Vue.use(VueMoment)
Vue.component('custom-label', Label)
Vue.component('custom-alert', Alert)
Vue.component('DatePicker', DatePicker)
Vue.component('custom-snackbar', Snackbar)
Vue.component('bloco-informativo', BlocoInformativo)
Vue.component('EmpresasSelect', EmpresasSelect)
Vue.component('ProducoesSelect', ProducoesSelect)
Vue.component('UsuariosAgricolaSelect', UsuariosAgricolaSelect)
Vue.component('ProdutoSelect', ProdutoSelect)
Vue.component('FornecedorSelect', FornecedorSelect)
Vue.component('BemPatrimonialSelect', BemPatrimonialSelect)
Vue.component('TalhoesSelect', TalhoesSelect)
Vue.component('InputMoney', InputMoney)
Vue.component('FiltroMobile', FiltroMobile)
Vue.component('Exportar', Exportar)
Vue.component('HeaderControl', HeaderControl)
Vue.component('ServicoSelect', ServicoSelect)
Vue.component('UsuarioSelect', UsuarioSelect)
Vue.component('EnderecosSelect', EnderecosSelect)
Vue.component('RPASelect', RPASelect)
Vue.component('EstadoSelect', EstadoSelect)
Vue.component('CidadeSelect', CidadeSelect)
Vue.component('CoordenadasInput', CoordenadasInput)

Vue.filter('datetime', date.datetimeFilter)
Vue.filter('date', date.dateFilter)
Vue.filter('formatNumber', stringFormat.formatNumber)
Vue.filter('formatBytes', stringFormat.formatBytes)

// Internal helper functions
Vue.prototype.$dataTableConfig = dataTableConfig
Vue.prototype.$dataTablefilter = dataTableFilter.filter
Vue.prototype.$label = labelFormat
Vue.prototype.$auth = auth
Vue.prototype.$empresas = empresas
Vue.prototype.$producoes = producoes
Vue.prototype.$stringFormat = stringFormat
Vue.prototype.$validations = validations
Vue.prototype.$array = array
Vue.prototype.$date = date

// API service init
ApiService.init()

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
