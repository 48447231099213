import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"clearable":"","dense":"","outlined":"","readonly":"","label":_vm.label,"append-icon":"mdi-calendar","rules":_vm.rules,"disabled":_vm.disabled,"persistent-placeholder":_vm.persistentPlaceholder},on:{"click:clear":function($event){_vm.selectedDate = null},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.clear.apply(null, arguments)}},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VDatePicker,{attrs:{"locale":"pt-BR","max":_vm.max,"min":_vm.min,"range":_vm.range},on:{"input":_vm.closeOnSelect},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }