export const date = {
  getISOToday () {
    const date = new Date()
    const day = date.getDate().toString().padStart(2, 0)
    const month = (date.getMonth() + 1).toString().padStart(2, 0)
    const year = date.getFullYear()

    return `${year}-${month}-${day}`
  },

  getDateNow () {
    const date = new Date()

    return date.toLocaleString('pt-BR')
  },

  getDateNowIso () {
    const date = new Date()

    return date.toISOString().split('T')[0]
  },

  getDatePreviousWeekIso () {
    const date = new Date()
    date.setDate(date.getDate() - 6)

    return date.toISOString().split('T')[0]
  },

  getFirstDay () {
    const date = new Date()
    const dateBr = new Date(date.getFullYear(), date.getMonth(), 1)

    return dateBr.toISOString().split('T')[0]
  },

  getLastDay () {
    const date = new Date()
    const dateBr = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    return dateBr.toISOString().split('T')[0]
  },

  weekOfYear (dateStr) {
    const today = new Date(dateStr)
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1)
    const pastDaysOfYear = (today.valueOf() - firstDayOfYear.valueOf()) / 86400000

    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
  },
  getYearByDate (date) {
    return date.split('-')[0]
  },

  convertBRLToISO (data) {
    return data.split('/').reverse().join('-')
  },

  validDate (data) {
    if (data == null) {
      return '-'
    }

    return data.indexOf('/') >= 0 ? data : data.split('-').reverse().join('/')
  },

  getDateByDateString (date) {
    const [year, month, day] = date.split('-')
    return new Date(year, month - 1, day)
  },

  monthDiff (dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
  },

  addDays (date, days) {
    const result = new Date(date || this.getDateNowIso())

    result.setDate(result.getDate() + days)

    return result.toISOString().split('T')[0]
  },

  datetimeFilter (value, returnIfEmptyValue = null) {
    if (value) {
      const moment = require('moment')

      return moment(value).format('DD/MM/YYYY HH:mm:ss');
    }

    return returnIfEmptyValue;
  },

  dateFilter (value, returnIfEmptyValue = null) {
    if (value) {
      const moment = require('moment')

      return moment(value).format('DD/MM/YYYY');
    }

    return returnIfEmptyValue;
  },
}
