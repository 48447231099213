import Vue from 'vue'
import Router from 'vue-router'

import { getToken, getUser } from '@/core/services/storage.service'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior () {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  },
  routes: [
    {
      name: 'login',
      path: '/login',
      component: () => import('@/view/pages/auth/Login'),
      meta: {
        authRequired: false,
      },
    },
    {
      path: '/aprovador',
      name: 'aprovador',
      component: () => import('@/view/pages/aprovador/Index.vue'),
      meta: {
        authRequired: true,
        gate: 'tela.compras.aprovador',
      },
    },
    {
      name: 'maintence',
      path: '/maintence',
      component: () => import('@/view/pages/Maintence'),
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/erro',
      name: 'erro',
      component: () => import('@/view/pages/error/Error.vue'),
      meta: {
        authRequired: true,
      },
      children: [
        {
          path: 'tela-nao-autorizada',
          name: 'tela-nao-autorizada',
          component: () => import('@/view/pages/error/TelaNaoAutorizada.vue'),
          meta: {
            authRequired: true,
          },
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('@/view/pages/Index.vue'),
          meta: {
            authRequired: true,
          },
        },
        {
          path: '/release-notes',
          name: 'release-notes',
          component: () => import('@/view/pages/ReleaseNotes.vue'),
          meta: {
            authRequired: true,
            gate: 'tela.exibir.release.notes',
          },
        },
        {
          path: '/agricola',
          name: 'agricola',
          component: () => import('@/view/pages/agricola/VueAgricola.vue'),
          children: [
            {
              path: '/agricola/historico-talhao',
              name: 'historico-talhao',
              component: () =>
                import('@/view/pages/agricola/historico-talhao/index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-historico-talhao',
              },
            },
            {
              path: '/estoque/historico-estoque',
              name: 'historico-estoque',
              component: () =>
                import('@/view/pages/agricola/historico-estoque/index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-historico-estoque',
              },
            },
            {
              path: '/agricola/talhao-liberado-colheita',
              name: 'talhao-liberado-colheita',
              component: () =>
                import('@/view/pages/agricola/talhaoLiberadoColheita.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-talhao-liberado-colheita',
              },
            },
            {
              path: '/agricola/liberacao-de-colheitas',
              name: 'liberacao-de-colheitas',
              component: () =>
                import('@/view/pages/agricola/LiberacaoColheitas.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-liberacao-colheita',
              },
            },
            {
              path: '/estoque/produtos',
              name: 'produtos',
              component: () => import('@/view/pages/agricola/Produtos.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-produtos',
              },
            },
            {
              path: '/agricola/oferta-de-grama',
              name: 'oferta-de-grama',
              component: () => import('@/view/pages/agricola/oferta-grama/index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-oferta-de-grama',
              },
            },
            {
              path: '/agricola/alteracoes-oferta-de-grama',
              name: 'alteracoes-oferta-de-grama',
              component: () =>
                import('@/view/pages/agricola/AlteracoesOfertaGrama.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-alteracoes-oferta-de-grama',
              },
            },
            {
              path: '/agricola/registros-de-colheitas',
              name: 'registros-de-colheitas',
              component: () =>
                import('@/view/pages/agricola/registro-colheitas/index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-registros-de-colheitas',
              },
            },
            {
              path: '/agricola/relatorio/retiradas-anuais',
              name: 'relatorio-retiradas-anuais',
              component: () =>
                import('@/view/pages/agricola/relatorio/retiradas-anuais/index'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-relatorio-de-retiradas-anuais',
              },
            },
            {
              path: '/relatorio/visitas',
              name: 'relatorio-visitas',
              component: () =>
                import('@/view/pages/agricola/relatorio/visitas/index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-relatorio-de-visitas',
              },
            },
            {
              path: '/relatorio/visitas-talhoes',
              name: 'relatorio-visitas-talhoes',
              component: () =>
                import('@/view/pages/agricola/relatorio/visitas-talhoes/index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-relatorio-de-visitas',
              },
            },
            {
              path: '/estoque/movimento-estoque',
              name: 'movimento-estoque',
              component: () =>
                import('@/view/pages/agricola/movimento-estoque/index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-movimento-estoque',
              },
            },
            {
              path: '/estoque/lancamento-combustivel',
              name: 'lancamento-combustivel',
              component: () =>
                import('@/view/pages/agricola/lancamento-combustivel/index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.lancamento-combustivel',
              },
            },
            {
              path: '/estoque/lancamento-insumos',
              name: 'lancamento-insumos',
              component: () =>
                import('@/view/pages/agricola/lancamento-insumos/index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.lancamento.insumos',
              },
            },
            {
              path: '/agricola/qualidade-dos-tapetes',
              name: 'qualidade-dos-tapetes',
              component: () =>
                import('@/view/pages/agricola/QualidadeTapete.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-qualidade-dos-tapetes',
              },
            },
            {
              path: '/estoque/relatorio-utilizacao-anual-de-produtos',
              name: 'relatorio-utilizacao-anual-de-produtos',
              component: () =>
                import('@/view/pages/agricola/UtilizacaoAnualProdutos.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-relatorio-utilizacao-anual-de-produtos',
              },
            },
            {
              path: '/relatorios/aplicacao-produto',
              name: 'relatorio-aplicacao-produto',
              component: () =>
                import('@/view/pages/agricola/relatorio/aplicacao-produto/index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.relatorio-aplicacao-produto',
              },
            },
            {
              path: '/agricola/situacao-producoes',
              name: 'situacao-producoes',
              component: () =>
                import(
                  '@/view/pages/agricola/SituacaoProducoes'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-situacao-producoes',
              },
            },
            {
              path: '/configuracoes/agricola/talhoes',
              name: 'talhoes',
              component: () => import('@/view/pages/configuracoes/agricola/Talhoes.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.agricola-talhao',
              },
            },
            {
              path: '/configuracoes/agricola/mails',
              name: 'mails',
              component: () => import('@/view/pages/configuracoes/agricola/disparos-email/index'),
              meta: {
                authRequired: true,
                gate: 'notification.index',
              },
            },

            {
              path: '/configuracoes/agricola/atividades-agricolas',
              name: 'atividades-agricolas',
              component: () =>
                import(
                  '@/view/pages/configuracoes/agricola/AtividadesAgricolas.vue'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.configuracoes-atividades-agricolas',
              },
            },
            {
              path: '/configuracoes/agricola/tipos-de-atividades',
              name: 'tipos-de-atividades',
              component: () =>
                import(
                  '@/view/pages/configuracoes/agricola/TiposAtividades.vue'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.configuracoes-tipos-de-atividades',
              },
            },
            {
              path: '/configuracoes/agricola/culturas',
              name: 'culturas',
              component: () =>
                import('@/view/pages/configuracoes/agricola/Culturas.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.configuracoes-culturas',
              },
            },
            {
              path: '/configuracoes/agricola/variedade-de-culturas',
              name: 'variedade-de-culturas',
              component: () =>
                import(
                  '@/view/pages/configuracoes/agricola/VariedadeCulturas.vue'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.configuracoes-variedade-de-culturas',
              },
            },
            {
              path: '/configuracoes/agricola/sistema-de-irrigacao',
              name: 'sistema-de-irrigacao',
              component: () =>
                import(
                  '@/view/pages/configuracoes/agricola/SistemaIrrigacao.vue'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.configuracoes-sistema-de-irrigacao',
              },
            },
            {
              path: '/configuracoes/agricola/unidades-de-medidas',
              name: 'unidades-de-medida',
              component: () =>
                import(
                  '@/view/pages/configuracoes/agricola/UnidadesMedida.vue'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.configuracoes-unidades-de-medidas',
              },
            },
            {
              path: '/configuracoes/agricola/horarios-agendamento',
              name: 'horarios-agendamento',
              component: () =>
                import(
                  '@/view/pages/configuracoes/agricola/HorariosAgendamento'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.configuracoes-horarios-agendamento',
              },
            },
            {
              path: '/configuracoes/agricola/motivos-alteracao-frete',
              name: 'motivo-alteracao-frete',
              component: () =>
                import(
                  '@/view/pages/configuracoes/agricola/MotivosFrete'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.configuracoes-motivos-alteracao-frete',
              },
            },
            {
              path: '/configuracoes/sistema/empresa',
              name: 'empresa',
              component: () =>
                import('@/view/pages/configuracoes/sistema/Empresa.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.configuracoes-liberacao-colheita',
              },
            },
            {
              path: '/configuracoes/sistema/producao',
              name: 'producao',
              component: () =>
                import('@/view/pages/configuracoes/sistema/Producao.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.configuracoes-producoes',
              },
            },
            {
              path: '/configuracoes/sistema/usuarios',
              name: 'usuarios',
              component: () =>
                import('@/view/pages/configuracoes/sistema/Usuarios.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.configuracoes-usuarios',
              },
            },
            {
              path: '/configuracoes/sistema/perfis-acesso',
              name: 'perfis-acesso',
              component: () =>
                import(
                  '@/view/pages/configuracoes/sistema/PerfisAcesso.vue'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.configuracoes-perfis-de-acesso',
              },
            },
            {
              path: '/configuracoes/sistema/tokens-acesso',
              name: 'tokens-acesso',
              component: () =>
                import(
                  '@/view/pages/configuracoes/sistema/TokenAcesso/Index.vue'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.configuracoes.tokens.de.acesso',
              },
            },
            {
              path: '/configuracoes/sistema/tokens-acesso/create',
              name: 'tokens-acesso-create',
              component: () =>
                import(
                  '@/view/pages/configuracoes/sistema/TokenAcesso/Create.vue'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.configuracoes.tokens.de.acesso',
              },
            },
            {
              path: '/vendas/faturamento/agenda-carregamento',
              name: 'agenda-carregamento',
              component: () =>
                import(
                  '@/view/pages/vendas/faturamento/AgendaCarregamento.vue'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.vendas-agenda-carregamento',
              },
            },
            {
              path: '/vendas/faturamento/carregamentos-concluidos',
              name: 'carregamentos-concluidos',
              component: () =>
                import(
                  '@/view/pages/vendas/faturamento/CarregamentosConcluidos.vue'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.vendas-carregamentos-concluidos',
              },
            },
            {
              path: '/vendas/faturamento/nota-fiscal',
              name: 'nota-fiscal',
              component: () =>
                import(
                  '@/view/pages/vendas/faturamento/nota-fiscal/index.vue'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.vendas-nota-fiscal',
              },
            },
            {
              path: '/vendas/faturamento/cobrancas',
              name: 'cobrancas',
              component: () =>
                import(
                  '@/view/pages/vendas/faturamento/cobrancas/index.vue'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.faturamento-cobrancas',
              },
            },
            {
              path: '/fretes/tabela-fretes',
              name: 'tabela-fretes',
              component: () =>
                import(
                  '@/view/pages/fretes/TabelaFretes'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.fretes-tabela-fretes',
              },
            },
            {
              path: '/fretes/lista-solicitacao',
              name: 'lista-solicitacoes-fretes',
              component: () =>
                import('@/view/pages/fretes/solicitacao-frete/listagem/index'),
              meta: {
                authRequired: true,
                gate: 'tela.fretes-solicitacao-frete',
              },
            },
            {
              path: '/fretes/adiciona-solicitacao',
              name: 'adicona-solicitacao-frete',
              component: () =>
                import('@/view/pages/fretes/solicitacao-frete/nova/index'),
              meta: {
                authRequired: true,
                gate: 'tela.fretes.criar.solicitacao.frete',
              },
            },
            {
              path: '/fretes/simulador-fretes',
              name: 'simulador-fretes',
              component: () =>
                import(
                  '@/view/pages/fretes/simulador-fretes/Index'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.fretes-tabela-fretes',
              },
            },
            {
              path: '/fretes/simulador-fretes/:id',
              name: 'simulador-fretes-criar-editar',
              component: () =>
                import(
                  '@/view/pages/fretes/simulador-fretes/CriarEditar'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.fretes-tabela-fretes',
              },
            },
            {
              path: '/fretes/routeasy',
              name: 'routeasy',
              component: () =>
                import(
                  '@/view/pages/fretes/routeasy/Index'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.routeasy',
              },
            },
            {
              path: '/fretes/routeasy/operadores',
              name: 'routeasy-operadores',
              component: () =>
                import(
                  '@/view/pages/fretes/routeasy/operadores/Index'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.routeasy',
              },
            },
            {
              path: '/fretes/conta-corrente',
              name: 'conta-corrente',
              component: () =>
                import(
                  '@/view/pages/fretes/conta-corrente/Index'
                ),
              meta: {
                authRequired: true,
                gate: 'tela.conta.corrente',
              },
            },
            {
              path: '/solicitacao/produtos',
              name: 'listagem-solicitacoes-compras',
              component: () =>
                import('@/view/pages/compras/produtos/index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.compras-listagem-solicitacoes',
              },
            },
            {
              path: '/solicitacao/produtos/novo',
              name: 'compras-solicitacao-compras',
              component: () =>
                import('@/view/pages/compras/produtos/novo.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.compras-solicitacao-compras',
              },
            },
            {
              path: '/solicitacao/servicos',
              name: 'servicos-listagem-ordem-servicos',
              component: () =>
                import('@/view/pages/compras/servicos/index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.servicos-listagem-ordem-servicos',
              },
            },
            {
              path: '/solicitacao/servicos/novo',
              name: 'servicos-ordem-servicos',
              component: () =>
                import('@/view/pages/compras/servicos/novo.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.servicos-ordem-servicos',
              },
            },
            {
              path: '/projeto-pesquisa/solicitacao',
              name: 'projeto-pesquisa-solicitacao-index',
              component: () =>
                import('@/view/pages/projeto-pesquisa/solicitacao/Index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.projeto.de.pesquisa.solicitacao',
              },
            },
            {
              path: '/projeto-pesquisa/solicitacao/create',
              name: 'projeto-pesquisa-solicitacao-create',
              component: () =>
                import('@/view/pages/projeto-pesquisa/solicitacao/CreateEdit.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.projeto.de.pesquisa.solicitacao',
              },
            },
            {
              path: '/projeto-pesquisa/solicitacao/:id/edit',
              name: 'projeto-pesquisa-solicitacao-edit',
              component: () =>
                import('@/view/pages/projeto-pesquisa/solicitacao/CreateEdit.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.projeto.de.pesquisa.solicitacao',
              },
            },
            {
              path: '/projeto-pesquisa/acompanhamento',
              name: 'projeto-pesquisa-acompanhamento-index',
              component: () =>
                import('@/view/pages/projeto-pesquisa/acompanhamento/Index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.projeto.de.pesquisa.acompanhamento',
              },
            },
            {
              path: '/projeto-pesquisa/acompanhamento/:id/edit',
              name: 'projeto-pesquisa-acompanhamento-edit',
              component: () =>
                import('@/view/pages/projeto-pesquisa/acompanhamento/CreateEdit.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.projeto.de.pesquisa.acompanhamento',
              },
            },
            {
              path: '/bi/agricola/carregamentos',
              name: 'bi-agricola-carregamentos',
              component: () =>
                import('@/view/pages/bi/agricola/Carregamentos.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.agricola.carregamentos',
              },
            },
            {
              path: '/bi/agricola/meta_producoes',
              name: 'bi-agricola-meta-producoes',
              component: () =>
                import('@/view/pages/bi/agricola/MetaProducoes.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.agricola.meta.producoes',
              },
            },
            {
              path: '/bi/financeiro/meta_ebitda',
              name: 'bi-financeiro-meta-ebitda',
              component: () =>
                import('@/view/pages/bi/financeiro/MetaEBITDA.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.financeiro.meta.ebitda',
              },
            },
            {
              path: '/bi/comercial/carteira_clientes_geral',
              name: 'bi-comercial-carteira-clientes-geral',
              component: () =>
                import('@/view/pages/bi/comercial/CarteiraClientesGeral.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.comercial.carteira.clientes.geral',
              },
            },
            {
              path: '/bi/comercial/carteira_clientes_av',
              name: 'bi-comercial-carteira-clientes-av',
              component: () =>
                import('@/view/pages/bi/comercial/CarteiraClientesAV.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.comercial.carteira.clientes.av',
              },
            },
            {
              path: '/bi/comercial/carteira_clientes_rtv',
              name: 'bi-comercial-carteira-clientes-rtv',
              component: () =>
                import('@/view/pages/bi/comercial/CarteiraClientesRTV.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.comercial.carteira.clientes.rtv',
              },
            },
            {
              path: '/bi/comercial/mapas',
              name: 'bi-comercial-mapas',
              component: () =>
                import('@/view/pages/bi/comercial/Mapas.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.comercial.mapas',
              },
            },
            {
              path: '/bi/comercial/metas',
              name: 'bi-comercial-metas',
              component: () =>
                import('@/view/pages/bi/comercial/Metas.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.comercial.metas',
              },
            },
            {
              path: '/bi/comercial/comissoes',
              name: 'bi-comercial-comissoes',
              component: () =>
                import('@/view/pages/bi/comercial/Comissoes.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.comercial.comissoes',
              },
            },
            {
              path: '/bi/comercial/oportunidades',
              name: 'bi-comercial-oportunidades',
              component: () =>
                import('@/view/pages/bi/comercial/Oportunidades.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.comercial.oportunidades',
              },
            },
            {
              path: '/bi/comercial/visitas',
              name: 'bi-comercial-visitas',
              component: () =>
                import('@/view/pages/bi/comercial/Visitas.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.comercial.visitas',
              },
            },
            {
              path: '/bi/comercial/leads',
              name: 'bi-comercial-leads',
              component: () =>
                import('@/view/pages/bi/comercial/Leads.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.comercial.leads',
              },
            },
            {
              path: '/bi/compras',
              name: 'bi-compras',
              component: () =>
                import('@/view/pages/bi/compras/Index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.compras',
              },
            },
            {
              path: '/bi/logistica',
              name: 'bi-logistica',
              component: () =>
                import('@/view/pages/bi/logistica/Index.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.logistica',
              },
            },
            {
              path: '/bi/rh/pagamentos',
              name: 'bi-rh-pagamentos',
              component: () =>
                import('@/view/pages/bi/rh/Pagamentos.vue'),
              meta: {
                authRequired: true,
                gate: 'tela.bi.rh.pagamentos',
              },
            },
          ],
        },
      ],
    },
  ],
})

function hasAccess (name) {
  const user = getUser()

  if (!user) {
    return false
  }

  const permissions = user.permissoes || []

  return permissions.includes(name)
}

router.beforeEach((to, from, next) => {
  const accessToken = getToken()

  if (to.meta.authRequired && !accessToken) {
    router.push({
      name: 'login',
      query: {
        to: to.name,
      },
    })

    return next()
  }

  if (!to.meta.authRequired && accessToken) {
    router.push({
      name: '/',
    })

    return next()
  }

  if (to.meta.gate && !hasAccess(to.meta.gate)) {
    router.push({
      name: 'tela-nao-autorizada',
    })
  }

  return next()
})

export default router
