export const dataTableConfig = {
  getItemsPerPage () {
    return 50
  },

  getItemsPerPageCustom () {
    return 500
  },

  getFooterProps () {
    return {
      'items-per-page-text': 'Registros por página: ',
      'items-per-page-options': [25, 50, 100, 500],
      'page-text': '{0}-{1} de {2}',
    }
  },

  getLoadText () {
    return 'Carregando...'
  },
}

export const dataTableFilter = {
  filter: (that, field, value, allOption, valueProperty) => {
    const filterValue = that[`${field}FilterValue`]

    if (filterValue === '' || filterValue === allOption) {
      return true
    }

    if (valueProperty !== undefined) {
      return value[valueProperty] === filterValue
    }

    return value === filterValue
  },

}
