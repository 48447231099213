import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{attrs:{"items":_vm.options,"loading":_vm.loading,"item-text":"label","item-value":"id","disabled":_vm.disabled || _vm.loading,"rules":_vm.computedRule,"dense":"","outlined":"","hide-no-data":"","persistent-placeholder":"","label":_vm.computedLabel,"auto-select-first":"","return-object":_vm.returnObject},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{class:{
      'd-inline-block text-truncate': true,
      'w-75': (_vm.model.length > 1),
    },staticStyle:{"max-width":"90%"}},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"ml-1 grey--text caption"},[_vm._v(" +"+_vm._s(_vm.model.length - 1)+" outra(s)... ")]):_vm._e()]}},{key:"prepend",fn:function(){return [(_vm.$slots.icon)?_c(VSlideXTransition,{attrs:{"mode":"out-in"}},[_vm._t("icon")],2):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})}
var staticRenderFns = []

export { render, staticRenderFns }