export const stringFormat = {
  monthName (mes) {
    const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

    return meses[mes]
  },

  markdownToHTML (value) {
    if (!value) {
      return ''
    }

    let showdown = require('showdown'),
        converter = new showdown.Converter()

    return converter.makeHtml(value)
  },

  formatNumber: (number, minFraction = 2, maxFraction = 2) => {
    if (number === null) {
      number = 0
    }

    return parseFloat(number).toLocaleString(
      'pt-BR',
      {
        minimumFractionDigits: minFraction,
        maximumFractionDigits: maxFraction,
      },
    )
  },

  formatBytes: (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  },

  formatNumberInteger: (number) => {
    return parseInt(number)
  },

  validarNumero ($event) {
    const key = $event.key

    if (
      key === 'Tab' ||
      key === 'Backspace' ||
      key === 'Delete' ||
      key === 'ArrowUp' ||
      key === 'ArrowDown' ||
      key === 'ArrowRight' ||
      key === 'ArrowLeft'
    ) {
      return
    }

    if (/\D/.test(key)) {
      $event.preventDefault()
    }
  },

  formatMoney: (value) => {
    return stringFormat.formatNumber(value)
  },

  formatM2: (value) => {
    return stringFormat.formatNumber(value, 0, 0)
  },

  formatDate (dateStr) {
    if (!dateStr || dateStr === '') {
      return null
    }

    const [year, month, day] = dateStr.split('-')
    return `${day}/${month}/${year}`
  },

  convertToFloatNumber (value) {
    if (typeof value === 'number') {
      return parseFloat(value)
    }

    // English Format
    if (value.indexOf('.') > value.indexOf(',') && value.includes('.')) {
      return parseFloat(value.replace(/,/gi, ''))
    }

    // Brazilian Format
    return parseFloat(value.replace(/\./gi, '').replace(/,/gi, '.'))
  },

  convertBSHToM2: (value) => {
    value = value * 2.5

    return parseFloat(value)
  },

  convertM2ToHectare: (value) => {
    const boolValue = !!value

    if (!boolValue) {
      return 0
    }

    return parseFloat(value.replace(/[.,]/g, '')).toFixed(2) / 10000
  },

  convertHectareToM2: (value) => {
    const boolValue = !!value

    if (!boolValue) {
      return 0
    }

    const metros = value.toString().replace(',', '.') * 10000
    return stringFormat.formatNumber(parseInt(metros), 0, 4)
  },

  dateISOToBRL: (date, defaultReturn) => {
    if (!date) {
      return defaultReturn ?? ''
    }

    return date.split(/\s|T/).reverse().pop().split('-').reverse().map((i) => ('0' + i).substr(i.length > 3 ? -4 : -2)).join('/')
  },
  dateISOToBRLMobile: (date) => {
    if (!date) {
      return ''
    }

    return date.split(/\s|T/).reverse().pop().split('-').reverse().map((i) => ('0' + i).substr(-2)).join('/')
  },
  queryParams: (value) => {
    const esc = encodeURIComponent

    return Object.keys(value)
      .filter((v) => value[v])
      .map((k) => esc(k) + '=' + esc(value[k]))
      .join('&')
  },
  queryParamsRecursiveKey (key, oldkey) {
    const esc = encodeURIComponent
    return !oldkey ? esc(key) : oldkey + '[' + esc(key) + ']'
  },
  queryParamsRecursive: (value, key) => {
    const esc = encodeURIComponent
    const queryParamsRecursiveKey = stringFormat.queryParamsRecursiveKey
    const queryParamsRecursive = stringFormat.queryParamsRecursive

    return Object.keys(value)
      .filter((v) => value[v] !== undefined && value[v] !== null && value[v] !== '')
      .map((k) => {
        if (typeof value[k] !== 'object') {
          return queryParamsRecursiveKey(k, key) + '=' + esc(value[k])
        }

        return queryParamsRecursive(value[k], queryParamsRecursiveKey(k, key))
      })
      .filter((k) => k !== '')
      .join('&')
  },

  dateToISO (data) {
    const dataHora = data.split(' ')
    let dataSomente = dataHora[0]
    dataSomente = dataSomente.replace(',', '')

    const dia = dataSomente.split('/')[0]
    const mes = dataSomente.split('/')[1]
    const ano = dataSomente.split('/')[2]

    return ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2)
  },

  truncate (str, length) {
    return str.length > length ? `${str.substring(0, length)}...` : str
  },

  capitalize (str) {
    return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase())
  },

  escapeRegex (str) {
    return str.replace(/([.?*+^$[\]\\(){}|-])/ig, '\\$1')
  },

  formatTelefone (telefone) {
    return telefone.toString().replace(/[^\d]/g, '').replace(/(\d{2})(\d{4})(\d{5})/, '($1) $2-$3')
  },

  formatCpf (cpf) {
    return cpf.toString().replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  },

}
