<template>
  <v-autocomplete
    v-model="model"
    :items="produtosOptions"
    :loading="loading"
    item-text="label"
    item-value="id"
    :disabled="disabled || readonly"
    :rules="computedRule"
    dense
    outlined
    hide-no-data
    persistent-placeholder
    :label="computedLabel"
    :small-chips="multiple"
    :chips="multiple"
    :deletable-chips="multiple"
    :multiple="multiple"
    :search-input.sync="search"
    no-filter
    auto-select-first
    :return-object="returnObject">
    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0" style="max-width: 90%" :class="{
        'd-inline-block text-truncate': true,
        'w-75': (model.length > 1),
      }">
        {{ item.label }}
      </span>
      <span v-if="index === 1" class="ml-1 grey--text caption">
        +{{ model.length - 1 }} outra(s)...
      </span>
    </template>
    <template v-slot:prepend>
      <v-slide-x-transition
          mode="out-in" v-if="$slots.icon"
      >
        <slot name="icon"></slot>
      </v-slide-x-transition>
    </template>

    <template v-slot:append-item>
      <div v-intersect="onIntersect" v-if="page < ultimaPagina && (page > 1 || !loading)" class="pl-3 pt-5">
        Carregando mais itens
      </div>
    </template>
  </v-autocomplete>
</template>
<script type="text/javascript">

import { debounce } from 'lodash'

import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: [String, Object],
      default: '',
    },
    nome: {
      type: [String, Number],
      default: 'default',
    },
    mostraTodosLabel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: [String, Object],
      default: '',
    },
    producao: {
      type: [String, Object],
      default: '',
    },
    rules: {
      type: Array,
      default () {
        return []
      },
    },
    estoque: {
      type: Boolean,
      default: false,
    },
    insumos: {
      type: Boolean,
      default: false,
    },
    combustiveis: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    textOption: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Produto(s)',
    },
    produtosDefault: {
      type: [Array, Object, String],
    },
  },
  data: () => ({
    options: [],
    optionSelected: null,
    searchDebound: null,
    loading: false,
    search: null,
    page: 1,
    ultimaPagina: 1,
  }),
  computed: {
    computedLabel () {
      return this.obrigatorio ? `${this.label} *` : this.label
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        if (this.multiple) {
          let firstIsAll = null
          const getIdByValue = this.getIdByValue

          val = val.reverse().filter(function (item) {
            if (firstIsAll === null) {
              firstIsAll = getIdByValue(item) === ''
              return true
            }

            return !firstIsAll && getIdByValue(item) !== ''
          })
        }

        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
    computedRule: {
      get () {
        return [(v) => !this.obrigatorio || !!v || 'Selecione o Produto'].concat(this.rules)
      },
      set (val) {},
    },
    produtosOptions () {
      let options = [].concat(this.options)

      if (this.textOption && this.search && this.options.length === (this.mostraTodosLabel ? 1 : 0)) {
        options = [{
          label: this.search,
          id: 'tx-' + this.search,
        }].concat(options)
      }

      return options
    },
    tipo () {
      if (this.insumos) {
        return 1
      }

      if (this.combustiveis) {
        return 2
      }

      return 0
    },
  },
  watch: {
    empresa () {
      if (!this.estoque) {
        this.defaultData()
        this.getData()
      }
    },
    producao () {
      this.defaultData()
      this.getData()
    },
    search (value) {
      this.defaultData()
      this.page = 1

      if (this.searchDebound) {
        this.searchDebound.cancel()
      }

      ApiService.cancel('produto.selecionado.' + this.nome)

      this.loading = true
      this.searchDebound = debounce(this.getData, 1000)
      this.searchDebound()
    },
    value (value) {
      this.getProdutoSelecionado()
    },
  },
  mounted () {
    this.defaultData()
    this.getData()
    this.getProdutoSelecionado()
  },
  methods: {
    getProdutoSelecionado () {
      if (!this.value) {
        this.optionSelected = null
        return
      }

      ApiService.cancel('produto.selecionado.' + this.nome)
        .get('/agricola/produtos/select/' + this.getIdByValue(this.value), {
          estoque: this.estoque,
          tipo: this.tipo,
        })
        .then((response) => {
          this.optionSelected = response.data.data
          this.options = this.options.concat(this.optionSelected)
        }).finally(() => {
          this.loading = false
        })
    },
    getIdByValue (value) {
      return typeof value === 'object' && value !== null ? value.id : value
    },
    onIntersect (a, b, c) {
      if (!c) {
        return
      }

      this.page++
      this.getData()
    },
    defaultData () {
      this.options = this.optionSelected ? this.optionSelected : []

      if (this.mostraTodosLabel) {
        this.options.push({ id: '', label: 'Todos' })
      }
    },
    async getData () {
      this.searchDebound = null

      console.log(this.nome)
      if (this.disabled) {
        return
      }

      this.loading = true

      const empresas = [].concat(this.empresa)

      const producoes = [].concat(this.producao)

      if (this.estoque && (producoes.length === 0 || !this.getIdByValue(producoes[0]))) {
        this.model = this.multiple ? [] : ''
        this.loading = false
        return
      }

      if (empresas.length > 1 || producoes.length > 1) {
        this.loading = false
        throw new Error('Multi select de empresas e produção não aplicados')
      }

      const response = await ApiService.cancel('produto.' + this.nome)
        .get('/agricola/produtos/select', {
          empresaId: this.getIdByValue(empresas[0]),
          producaoId: this.getIdByValue(producoes[0]),
          estoque: this.estoque,
          tipo: this.tipo,
          search: this.search,
          page: this.page,
        })

      if (response.data) {
        this.options.push(...response.data.data)
        this.ultimaPagina = response.data.meta.last_page
      }

      this.loading = false
    },
  },
}
</script>
