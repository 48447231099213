<template>
  <div>
    <b-toast
        v-for="(toast, index) in toasts"
        :key="index"
        v-model="toast.status"
        :title="toast.title"
        :variant="toast.color"
        :no-auto-hide="toast.timeout <= 0"
        :auto-hide-delay="toast.timeout"
        :no-close-button="toast.noCloseButton"
        :no-hover-pause="true"
        append-toast
        body-class="v-application"
    >
      <span>{{ toast.text }}</span>
      <v-progress-linear
        indeterminate
        color="primary"
        v-show="toast.progressbar"
      />
    </b-toast>
  </div>
</template>

<script type="text/javascript">
export default {
  props: {
  },
  data: () => ({
    colors: {
      success: 'success',
      alert: 'warning',
      danger: 'danger',
      primary: 'primary',
    },
    toasts: [],
  }),
  methods: {
    show ($title, $text, $color = 'success', $timeout = 3000, $progressbar = false, $append = false) {
      const toast = {
        status: true,
        title: $title,
        text: $text,
        color: this.colors[$color] || 'default',
        progressbar: $progressbar,
        timeout: $timeout,
        append: $append,
      }

      if (!$append) {
        this.toasts = this.toasts.map((toast) => {
          if (!toast.append) {
            toast.status = false
          }

          return toast
        })
      }

      this.toasts.push(toast)
    },
    close () {
      this.toasts = this.toasts.map((toast) => {
        if (!toast.append) {
          toast.status = false
        }

        return toast
      })
    },
  },
}
</script>
