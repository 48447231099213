import ApiService from '@/core/services/api.service'
import StorageService from '@/core/services/storage.service'

import { array } from '../../functions/array'

const SITUACOES_PRODUCOES_CLASS = {
  0: 'd-none',
  1: 'bg-funcionando',
  2: 'bg-funcionandoParcialmente',
  3: 'bg-naoFuncionando',
}
// mutation types
export const UPDATE_SITUACAO_PRODUCAO = 'updateSituacaoProducao'
export const SET_STATUS = 'setStatus'
export const SET_ERROR = 'setError'

const state = {
  errors: null,
  status_producao: StorageService.getStatusProducoes() || {},
}

const getters = {
  statusProducoes (state) {
    return state.status_producao
  },
  situacaoProducao: (state) => (empresaId, producaoId, data) => {
    if (!(state.status_producao instanceof Map)) {
      return []
    }

    if (!state.status_producao.get(data)) {
      return []
    }

    return state.status_producao.get(data)
      .find(
        (statusProducao) =>
          statusProducao.empresa_id === Number(empresaId) && statusProducao.producao_id === Number(producaoId),
      )
  },
  situacaoCodigo: (state, getters) => (empresaId, producaoId, data) => {
    const statusProducao = getters.situacaoProducao(empresaId, producaoId, data)
    return statusProducao ? statusProducao.situacao_producao : 0
  },
  situacaoProducaoNome: (state, getters) => (empresaId, producaoId, data) => {
    const statusProducao = getters.situacaoProducao(empresaId, producaoId, data)
    return statusProducao ? statusProducao.situacao_nome : ''
  },
  situacaoProducaoClass: (state, getters) =>
    (empresaId, producaoId, data) =>
      SITUACOES_PRODUCOES_CLASS[getters.situacaoCodigo(empresaId, producaoId, data)] || 'd-none',
}

const actions = {
  [UPDATE_SITUACAO_PRODUCAO] (context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get('situacao-producao/find-last/', `${params.data}`)
        .then(({ data }) => {
          context.commit(SET_STATUS, data.data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response.data.errors)
        })
    })
  },
}

const mutations = {
  [SET_STATUS] (state, situacoes) {
    state.status_producao = array.groupBy(situacoes, (situacao) => situacao.data_cadastro)
    state.errors = {}
    StorageService.saveStatusProducoes(state.status_producao)
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
