const STORAGE_TOKEN = 'storage_token'
const STORAGE_USER = 'storage_user'
const STORAGE_STATUS_PRODUCOES = 'status_producoes'
const STORAGE_PRODUTOS = 'produtos'

export const destroyAll = () => {
  window.localStorage.removeItem(STORAGE_TOKEN)
  window.localStorage.removeItem(STORAGE_USER)
}

export const getToken = () => {
  return window.localStorage.getItem(STORAGE_TOKEN)
}

export const saveToken = (token) => {
  window.localStorage.setItem(STORAGE_TOKEN, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(STORAGE_TOKEN)
}

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem(STORAGE_USER))
}

export const can = (permissao) => {
  const permissions = getUser()?.permissoes || []
  return permissions.includes(permissao)
}

export const getStatusProducoes = () => {
  const statusProducoes = JSON.parse(window.localStorage.getItem(STORAGE_STATUS_PRODUCOES))

  if (Array.isArray(statusProducoes)) {
    saveStatusProducoes({})
    return {}
  }

  return statusProducoes
}

export const saveStatusProducoes = (data) => {
  window.localStorage.setItem(STORAGE_STATUS_PRODUCOES, JSON.stringify(data))
}

export const saveUser = (user) => {
  window.localStorage.setItem(STORAGE_USER, JSON.stringify(user))
}

export const destroyUser = () => {
  window.localStorage.removeItem(STORAGE_USER)
}

export const getProdutos = () => {
  const produtos = JSON.parse(window.sessionStorage.getItem(STORAGE_PRODUTOS))

  if (Array.isArray(produtos)) {
    saveProdutos({})
    return {}
  }

  return produtos
}

export const saveProdutos = (produtos) => {
  window.sessionStorage.setItem(STORAGE_PRODUTOS, JSON.stringify(produtos))
}

export const destroyProdutos = () => {
  window.sessionStorage.removeItem(STORAGE_PRODUTOS)
}

export default { destroyAll, getToken, saveToken, destroyToken, getUser, saveUser, destroyUser, getStatusProducoes, saveStatusProducoes, getProdutos, saveProdutos, destroyProdutos }
