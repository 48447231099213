<template>
  <v-text-field
    v-bind="$props"
    v-money="moneyMask"
    :rules="rule"
    v-model="model"/>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '0,00',
    },
    outlined: Boolean,
    dense: Boolean,
    'persistent-placeholder': Boolean,
    label: {
      type: String,
      default: '',
    },
    disabled: Boolean,
    precision: {
      type: Number,
      default: 2,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
  },
  data: function (instance) {
    return {
      rule: [(v) => !instance.obrigatorio || parseFloat(v) === '0,00' || !!parseFloat(v) || 'Informe o valor'],
      moneyMask: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: instance.precision,
      },
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
}
</script>
