import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":!_vm.disabled? 'primary': 'secondary',"dark":"","title":"Exportar"}},'v-btn',attrs,false),!_vm.disabled ? on: null),[_c(VIcon,{attrs:{"dark":"","color":!_vm.disabled? 'white' : 'black'}},[_vm._v("mdi-printer")])],1)]}}],null,true)},[_c('custom-snackbar',{ref:"snackbar"}),_c(VList,_vm._l((_vm.listaAcoes),function(item,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.exportar(item.tipo, item.acao)}}},[_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)}),1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }