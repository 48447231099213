import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Swal from 'sweetalert2'
import { saveAs } from 'file-saver'

import StorageService from '@/core/services/storage.service'
import { LOGOUT } from '@/core/services/store/auth.module'
import { ADD_BACKEND_ERRORS } from '@/core/services/store/validation.module'

import store from './store'
import router from '../../router'
import { stringFormat } from '../functions/string-format'

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  apiFetch: [],
  cancelName: null,
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL
    this.setHeader()
    this.handleUnauthorized()
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader () {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${StorageService.getToken()}`
    Vue.axios.defaults.timeout = 5 * 60 * 1000
  },

  handleUnauthorized () {
    Vue.axios.interceptors.response.use(function (response) {
      if (response.headers['x-render-report'] !== undefined) {
        const printTab = window.open()

        setTimeout(function () {
          printTab.document.write(response.data)
        }, 350)
      }

      return response
    }, function (err) {
      return new Promise(function (resolve, reject) {
        if (axios.isCancel(err)) {
          return
        }

        if (!err.response && router.currentRoute.path === '/maintence') {
          throw err
        }

        if (err.config.url === '/ping') {
          router.push('/maintence', () => {})
          return
        }

        if (!err.response) {
          axios.get('/ping').then(() => {
            Swal.fire({
              title: 'Tempo de requisição',
              text: 'A consulta está demorando muito, tente novamente, se repetir espere um pouco antes da nova tentativa!',
              icon: 'error',
              heightAuto: false,
              timer: 5000,
              timerProgressBar: true,
            })

            throw err
          })

          return
        }

        if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
          store.dispatch(LOGOUT)
          router.push('/login', () => {})
        }

        if (err.response.status === 422 && err.config && !err.config.__isRetryRequest) {
          store.dispatch(ADD_BACKEND_ERRORS, err.response.data.errors)
        }

        if (err.response.status === 403 && err.config && !err.config.__isRetryRequest) {
          let timerInterval

          Swal.fire({
            title: '',
            text: 'Você não possui permissão de acesso!',
            icon: 'error',
            confirmButtonClass: 'btn btn-secondary',
            heightAuto: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')

              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            },
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('Você não possui permissão de acesso!')
            }
          })
        }

        throw err
      })
    })
  },

  query (resource, params) {
    return Vue.axios.get(resource, params)
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @param options
   * @returns {*}
   */
  get (resource, slug = '', options = {}) {
    if (typeof slug === 'object') {
      slug = '?' + stringFormat.queryParamsRecursive(slug)
    }

    if (this.cancelName !== null) {
      this.apiFetch[this.cancelName || 'default'] = axios.CancelToken.source()

      options = Object.assign({
        cancelToken: this.apiFetch[this.cancelName || 'default'].token,
      }, options)

      this.cancelName = null
    }

    return Vue.axios.get(`${resource}${slug}`, options)
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post (resource, params) {
    return Vue.axios.post(`${resource}`, params)
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update (resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put (resource, params) {
    return Vue.axios.put(`${resource}`, params)
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  delete (resource, params = {}) {
    return Vue.axios.delete(resource, params)
  },

  parseParamsQuery (params) {
    return stringFormat.queryParamsRecursive(params)
  },

  cancel (name) {
    if (this.apiFetch[name || 'default']) {
      this.apiFetch[name || 'default'].cancel()
    }

    this.cancelName = name

    return this
  },

  async _get(resource, config= {}) {
    let response = null

    try {
      if (typeof config.params !== 'undefined') {
        config.params = new URLSearchParams(config.params)
      }

      response = await Vue.axios.get(resource, config)

      return response
    } catch (e) {
      if (e.response) {
        if (e.response.data.message) {
          if (config.snackbar) {
            config.snackbar.show(
                'Desculpe, algo deu errado!',
                e.response.data.message,
                'danger',
            )
          }
        } else {
          if (config.snackbar) {
            config.snackbar.show(
                'Desculpe, algo deu errado!',
                'Tente novamente mais tarde',
                'danger',
            )
          }
        }
      } else {
        if (config.snackbar) {
          config.snackbar.show('Desculpe, algo deu errado!', e, 'danger')
        }
      }
      console.log(config.snackbar)
      throw e
    }
  },

  async _post(resource, params = undefined, config = {}) {
    try {
      const response = await Vue.axios.post(resource, params, config)

      if (config.snackbar) {
        config.snackbar.show('Sucesso!', 'Operação realizada com sucesso.', 'success')
      }

      return response
    } catch (e) {
      if (e.response) {
        if (e.response.status === 422) {
          if (config.snackbar) {
            config.snackbar.show(
                'Desculpe, algo deu errado!',
                'Verifique os campos em vermelho e tente novamente',
                'danger',
                3000
            )
          }
        } else if (e.response.data.message) {
          if (config.snackbar) {
            config.snackbar.show(
                'Desculpe, algo deu errado!',
                e.response.data.message,
                'danger',
            )
          }
        } else {
          if (config.snackbar) {
            config.snackbar.show(
                'Desculpe, algo deu errado!',
                'Tente novamente mais tarde',
                'danger',
            )
          }
        }
      } else {
        if (config.snackbar) {
          config.snackbar.show('Desculpe, algo deu errado!', e, 'danger')
        }
      }

      throw e
    }
  },

  async _put(resource, params = undefined, config = {}) {
    try {
      const response = await Vue.axios.put(resource, params)

      if (config.snackbar) {
        config.snackbar.show('Sucesso!', 'Registro salvo com sucesso.', 'success')
      }

      return response
    } catch (e) {
      if (e.response) {
        if (e.response.status === 422) {
          if (config.snackbar) {
            config.snackbar.show(
                'Desculpe, algo deu errado!',
                'Verifique os campos em vermelho e tente novamente',
                'danger',
                3000
            )
          }
        } else if (e.response.data.message) {
          if (config.snackbar) {
            config.snackbar.show(
                'Desculpe, algo deu errado!',
                e.response.data.message,
                'danger',
            )
          }
        } else {
          if (config.snackbar) {
            config.snackbar.show(
                'Desculpe, algo deu errado!',
                'Tente novamente mais tarde',
                'danger',
            )
          }
        }
      } else {
        if (config.snackbar) {
          config.snackbar.show('Desculpe, algo deu errado!', e, 'danger')
        }
      }

      throw e
    }
  },

  async _delete(resource, config = {}) {
    try {
      const response = await Vue.axios.delete(resource, config)

      if (config.snackbar) {
        config.snackbar.show('Sucesso!', 'Registro excluído com sucesso.', 'success')
      }

      return response
    } catch (e) {
      if (e.response) {
        if (e.response.status === 422) {
          if (config.snackbar) {
            config.snackbar.show(
                'Desculpe, algo deu errado!',
                'Verifique os campos em vermelho e tente novamente',
                'danger',
                3000
            )
          }
        } else if (e.response.data.message) {
          if (config.snackbar) {
            config.snackbar.show(
                'Desculpe, algo deu errado!',
                e.response.data.message,
                'danger',
            )
          }
        } else {
          if (config.snackbar) {
            config.snackbar.show(
                'Desculpe, algo deu errado!',
                'Tente novamente mais tarde',
                'danger',
            )
          }
        }
      } else {
        if (config.snackbar) {
          config.snackbar.show('Desculpe, algo deu errado!', e, 'danger')
        }
      }

      throw e
    }
  },

  async downloadReport (type, report, params = undefined, snackbar = undefined) {
    try {
      // based on type argument get mimetype and extension
      let mime = {}

      if (type === 'pdf') {
        mime = { type: 'application/pdf', extension: 'pdf' }
      } else if (type === 'excel') {
        mime = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', extension: 'xlsx' }
      } else {
        new Error('Unable to define report type, the supported types are [pdf, excel].')
      }

      // validate if a report was provided, and its type
      if (typeof report !== 'string') {
        new Error('Invalid report, report must be an instance of String and refer to a report route.')
      }

      // mount the report url
      const url = `/${type}/${report}`

      if (snackbar) { // check if a snackbar was provided the call it
        snackbar.show('Gerando seu relatório.', 'Aguarde, o download iniciará automaticamente.', 'success', -1, true)
      }

      // request report
      const response = await axios.get(
        url,
        {
          baseURL: process.env.VUE_APP_REPORT_URL,
          responseType: 'blob',
          params: params,
        },
      )

      // create blob object to be downloaded with the responde data
      const blob = await new Blob([response.data], {
        type: mime.type,
      })

      // download the blob as a file
      await saveAs(blob, report + '_' + new Date().getTime() + '.' + mime.extension)

      if (snackbar) { // check if a snackbar was provided the call it
        snackbar.show('Oba! Deu tudo certo.', 'Seu arquivo foi gerado com sucesso', 'success')
      }
    } catch (e) {
      // since we have set a blob responseType to axios, it is needed to handle an error response
      const response = await e.response?.data?.text()

      let msg = ''

      if (response && JSON.parse(response).message.length > 0) {
        msg = JSON.parse(response).message
      } else if (process.env.NODE_ENV === 'production') {
        msg = 'O servidor não pode atender esta solicitação.'
      } else {
        msg = e
      }

      if (snackbar) { // check if a snackbar was provided the call it
        snackbar.show('Ops! Algo deu errado.', msg, 'danger')
      }
    }
  },
}

export default ApiService
