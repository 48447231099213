
// action types
export const CLEAR = 'clear'
export const ADD_RULES = 'addRules'
export const ADD_MESSAGES = 'addMessages'
export const ADD_COLUMNS = 'addColumns'
export const ADD_BACKEND_ERRORS = 'addBackendErrors'

// mutation types
export const SET_RULES_FRONT = 'setRulesFront'
export const SET_MESSAGES = 'setMessages'
export const SET_COLUMNS = 'setColumns'
export const SET_BACKEND_ERRORS = 'setBackendErrors'
export const UPDATE_RULES = 'updateRules'

const Validations = {
  required (state, column) {
    return (v) => !!v || state.messages.required
      .replace(':column', state.columns[column] ?? column)
  },
  min (state, column, minimun) {
    return (v) => v >= minimun || state.messages.min
      .replace(':column', state.columns[column] ?? column)
      .replace(':minimun', minimun)
  },
  max (state, column, maximun) {
    return (v) => v <= maximun || state.messages.max
      .replace(':column', state.columns[column] ?? column)
      .replace(':maximun', maximun)
  },
}

const state = {
  rulesFront: {},
  rules: {},
  messages: {
    required: 'Campo obrigatório',
    min: 'Valor não pode ser menor que o minimo de :minimun',
    max: 'Valor não pode ser maior que o máximo de :maximun',
  },
  columns: {},
  backendErrors: {},
}

const getters = {
  getRules (state) {
    return state.rules
  },
  getRule (state) {
    return function (column) {
      return state.rules[column] ?? []
    }
  },
}

const actions = {
  [CLEAR] (context) {
    context.commit(CLEAR)
  },
  [ADD_RULES] (context, rules) {
    context.commit(SET_RULES_FRONT, rules)
    context.commit(UPDATE_RULES)
  },
  [ADD_MESSAGES] (context, messages) {
    context.commit(SET_MESSAGES, messages)
    context.commit(UPDATE_RULES)
  },
  [ADD_COLUMNS] (context, columns) {
    context.commit(SET_COLUMNS, columns)
    context.commit(UPDATE_RULES)
  },
  [ADD_BACKEND_ERRORS] (context, backendErrors) {
    context.commit(SET_BACKEND_ERRORS, backendErrors)
    context.commit(UPDATE_RULES)
  },
}

const mutations = {
  [CLEAR] (state, rules) {
    state.rulesFront = {}
    state.rules = {}
    state.columns = {}
    state.backendErrors = {}

    state.messages = {
      required: 'Campo obrigatório',
      min: 'Valor não pode ser menor que o minimo de :minimun',
      max: 'Valor não pode ser maior que o máximo de :maximun',
    }
  },
  [SET_RULES_FRONT] (state, rules) {
    state.rulesFront = Object.assign(state.rulesFront, rules)
  },
  [SET_MESSAGES] (state, messages) {
    state.messages = Object.assign(state.messages, messages)
  },
  [SET_COLUMNS] (state, columns) {
    state.columns = Object.assign(state.columns, columns)
  },
  [SET_BACKEND_ERRORS] (state, backendErrors) {
    for (const column in backendErrors) {
      backendErrors[column] = backendErrors[column][0]
    }

    state.backendErrors = backendErrors
  },
  [UPDATE_RULES] (state) {
    const rules = {}

    for (const column in state.rulesFront) {
      const rulesFront = [(v) => state.backendErrors[column] ?? true]

      for (const rule of state.rulesFront[column]) {
        let [ruleName, args] = rule.split(':')

        const clousure = Validations[ruleName]

        if (!clousure) {
          continue
        }

        args = args ? args.split(':') : []

        rulesFront.push(clousure.apply(Validations, [state, column, ...args]))
      }

      rules[column] = rulesFront
    }

    state.rules = rules
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
