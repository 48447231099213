import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{attrs:{"label":_vm.computedLabel,"items":_vm.options,"loading":_vm.loading,"search-input":_vm.search,"hide-no-data":"","item-text":"descricao","item-value":"id","dense":"","outlined":"","no-filter":"","multiple":_vm.multiple,"rules":_vm.rule,"return-object":_vm.returnObject,"disabled":_vm.disabled || _vm.readonly},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"90%"}},[_vm._v(" "+_vm._s(item.descricao)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"ml-1 grey--text caption"},[_vm._v(" +"+_vm._s(_vm.model.length - 1)+" outra(s)... ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.descricao))]),_c(VListItemContent,[_vm._v(" "+_vm._s(item.endereco))])],1)]}},{key:"append-item",fn:function(){return [(_vm.page < _vm.ultimaPagina && (_vm.page > 1 || !_vm.loading))?_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],staticClass:"pl-3 pt-5"},[_vm._v(" Carregando mais itens ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})}
var staticRenderFns = []

export { render, staticRenderFns }