<template>
  <div :class="{ 'bordered': bordered }">
    <slot></slot>
    <v-tooltip :right="right" :bottom="bottom" v-if="textoInformativo">
      <template v-slot:activator="{ on, attrs }">
        <button class="question-button" v-bind="attrs" v-on="on"><b-icon-question-circle/></button>
      </template>
      <span>{{ textoInformativo }}</span>
    </v-tooltip>
  </div>
</template>
<style>
.question-button {
  position: absolute;
  right: 10px;
  top: -1px;
  font-size: 14px;
  background: #395764;
  color: white;
  border-radius: 30px;
  width: 18px;
  height: 18px;
  padding: 1px;
}
.bordered {
  margin: -2rem -1rem 0;
  padding: calc(2rem - 2px) calc(1rem - 2px) calc(-2px);
  border: 2px solid #395764;
  border-radius: 5px;
}
</style>

<script>

export default {
  props: {
    textoInformativo: {
      type: String,
    },
    right: {
      type: Boolean,
      default: true,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
    }
  },
  mounted () {
  },
}
</script>
