<template>
  <v-autocomplete
    v-model="model"
    :clearable="clearable"
    :hide-details="hideDetails"
    :items="options"
    :loading="loading"
    item-text="name"
    item-value="id"
    :disabled="disabled || loading"
    :rules="computedRule"
    dense
    outlined
    hide-no-data
    persistent-placeholder
    :label="computedLabel"
    :small-chips="multiple"
    :chips="multiple"
    :deletable-chips="multiple"
    :multiple="multiple"
    :return-object="returnObject"
    :error-messages="errorMessages"
  >
    <template v-slot:prepend>
      <v-slide-x-transition
          mode="out-in" v-if="$slots.icon"
      >
        <slot name="icon"></slot>
      </v-slide-x-transition>
    </template>
  </v-autocomplete>
</template>
<script type="text/javascript">

import ApiService from '@/core/services/api.service'

export default {
  name: 'UsuariosAgricolaSelect',
  props: {
    value: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      default: 'Usuário',
    },
    mostraTodosLabel: {
      type: Boolean,
      default: false,
    },
    empresaId: {
      type: [String, Number],
      default: '',
    },
    producaoId: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    buscaUsuarioServico: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    errorMessages: {
      type: String,
      default: null,
    }
  },
  data: () => ({
    options: [],
    loading: false,
  }),

  mounted () {
    this.getUsuarios()
  },
  computed: {
    computedLabel () {
      return this.obrigatorio ? `${this.label} *` : this.label
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
    computedRule: {
      get () {
        return [(v) => !this.obrigatorio || !!v || 'Selecione um usuário'].concat(this.rules)
      },
      set (val) {},
    },
  },
  watch: {
    producao () {
      this.getUsuarios()
    },
  },
  methods: {
    async getUsuarios () {
      this.loading = true

      const params = {
        empresa_id: this.empresaId,
        producao_id: this.producaoId,
      }

      try {
        this.options = (await ApiService.get(
          'usuario', '?' + this.$stringFormat.queryParams(params))
        ).data?.data
      } catch (e) {}

      this.loading = false
    },
  },
}
</script>
