import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth.module'
import htmlClass from './htmlclass.module'
import config from './config.module'
import breadcrumbs from './breadcrumbs.module'
import profile from './profile.module'
import filter from './filter.module'
import statusProducao from './producao.status.module'
import produtos from './produtos.module'
import validation from './validation.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    filter,
    statusProducao,
    produtos,
    validation,
  },
})
