import store from '@/core/services/store'

export const empresas = {
  list: (showTodasLabel = true) => {
    const empresasLista = []

    if (showTodasLabel) {
      empresasLista.push({ id: '', nome: 'Todas' })
    }

    for (const empresa of store.getters.currentUser.empresas) {
      empresasLista.push({
        id: empresa.id,
        nome: empresa.nome,
      })
    }

    return empresasLista
  },

  listSvis: (showTodasLabel = true) => {
    const svisLista = []

    if (showTodasLabel) {
      svisLista.push({ id: '', nome: 'Todas' })
    }

    for (const svi of store.getters.currentUser.svis) {
      svisLista.push({
        id: svi.id,
        nome: svi.nome,
      })
    }



    return svisLista
  },
}
