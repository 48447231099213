<template>
  <v-autocomplete
    v-model="model"
    :label="computedLabel"
    :items="options"
    item-text="nome"
    :item-value="itemValue"
    :loading="loading"
    dense
    outlined
    :rules="rules"
    :return-object="returnObject"
    :disabled="disabled || readonly"
    :hide-details="hideDetails"
    :persistent-placeholder="persistentPlaceholder"
  />
</template>

<script type="text/javascript">
import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: [String, Object, Array],
      default: '',
    },
    mostraTodosLabel: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    svi: {
      type: Boolean,
      default: false,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'UF',
    },
  },
  data: (instance) => ({
    rules: [(v) => !instance.obrigatorio || !!v || 'Selecione um Estado'],
    options: [],
    loading: false,
  }),
  watch: {
    value (val) {
      if (this.returnObject) {
        val = this.options.filter((item) => item.id === this.getIdByValue(val))[0]
      }

      this.$emit('input', val)
      this.$emit('change', val)
    },
  },
  computed: {
    computedLabel () {
      return this.obrigatorio ? `${this.label} *` : this.label
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  mounted () {
    this.getData()
  },
  methods: {
    getIdByValue (value) {
      return typeof value === 'object' ? value.id : value
    },
    getData () {
      this.loading = true

      ApiService
        .get('/fretes/ibge/estados')
        .then((response) => {
          this.loading = false
          const options = this.mostraTodosLabel ? [{ id: '', nome: 'Todos' }] : []
          this.options = options.concat(response.data)
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>
