export const array = {

  pluck (array, key) {
    if (!Array.isArray(array)) return [];
    return array.map((obj) => {
      return obj[key]
    })
  },

  unique (array) {
    return array.filter((value, index, self) => {
      return self.indexOf(value) === index
    })
  },
  groupBy (list, keyGetter) {
    const map = new Map()

    list.forEach((item) => {
      const key = keyGetter(item)
      const collection = map.get(key)

      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })

    return map
  },
}
