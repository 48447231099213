<template>
  <v-autocomplete
    v-model="model"
    :label="computedLabel"
    :items="options"
    :loading="loading"
    :search-input.sync="search"
    hide-no-data
    item-text="descricao"
    item-value="id"
    dense
    outlined
    no-filter
    :multiple="multiple"
    :rules="rule"
    :return-object="returnObject"
    :disabled="disabled || readonly"
  >
    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0" style="max-width: 90%" class="d-inline-block text-truncate">
        {{ item.descricao }}
      </span>
      <span v-if="index === 1" class="ml-1 grey--text caption">
        +{{ model.length - 1 }} outra(s)...
      </span>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ item.descricao }}</v-list-item-title>
        <v-list-item-content> {{ item.endereco }}</v-list-item-content>
      </v-list-item-content>
    </template>
    <template v-slot:append-item>
      <div v-intersect="onIntersect" v-if="page < ultimaPagina && (page > 1 || !loading)" class="pl-3 pt-5">
        Carregando mais itens
      </div>
    </template>
  </v-autocomplete>
</template>

<script type="text/javascript">

import { debounce } from 'lodash'

import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: [String, Object, Array],
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'select-enderecos',
    },
    label: {
      type: String,
      default: 'Endereço',
    },
  },
  data: (instance) => ({
    rule: [(v) => !instance.obrigatorio || !!v || 'Selecione um Endereço'],
    options: [],
    optionSelected: null,
    searchDebound: null,
    loading: false,
    search: '',
    page: 1,
    ultimaPagina: 1,
  }),
  computed: {
    computedLabel () {
      return this.obrigatorio ? `${this.label} *` : this.label
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        if (this.multiple) {
          if (val.length === 0) {
            val = ['']
          }

          const getIdByValue = this.getIdByValue
          let firstIsAll = null

          val = val.reverse().filter(function (item) {
            if (firstIsAll === null) {
              firstIsAll = getIdByValue(item) === ''
              return true
            }

            return !firstIsAll && getIdByValue(item) > 0
          })
        }

        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  watch: {
    search (value) {
      this.page = 1
      this.options = this.optionSelected ? this.optionSelected : []

      if (this.searchDebound) {
        this.searchDebound.cancel()
      }

      ApiService.cancel('selecionado.' + this.name)

      this.loading = true

      this.searchDebound = debounce(this.getData, 1000)
      this.searchDebound()
    },
    value (value) {
      if (!value) {
        this.optionSelected = null
        return
      }

      ApiService.cancel('selecionado.' + this.name)
        .get('fretes/enderecos/select/' + this.getIdByValue(value))
        .then((response) => {
          this.optionSelected = response.data.data
          this.options = this.options.concat(this.optionSelected)
        }).finally(() => {
          this.loading = false
        })
    },
  },
  mounted () {
    this.getData()
  },
  methods: {
    getIdByValue (value) {
      return typeof value === 'object' ? value.id : value
    },
    onIntersect (a, b, c) {
      if (!c) {
        return
      }

      this.page++
      this.getData()
    },
    getData () {
      this.loading = true
      this.searchDebound = null

      ApiService.cancel(this.name).get('fretes/enderecos/select', {
        search: this.search,
        page: this.page,
      }).then((response) => {
        this.options = this.options.concat(response.data.data)
        this.ultimaPagina = response.data.meta.last_page
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
