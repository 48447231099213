<template>
  <v-autocomplete
    v-model="model"
    :clearable="clearable"
    :label="computedLabel"
    :items="producoesOptions"
    item-text="nome"
    item-value="id"
    dense
    outlined
    :multiple="multiple"
    :rules="producaoRule"
    :return-object="returnObject"
    :disabled="disabled || readonly"
    :hide-details="hideDetails"
    :persistent-placeholder="persistentPlaceholder"
  >
    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0" style="max-width: 90%" class="d-inline-block text-truncate">
        {{ item.nome }}
      </span>
      <span v-if="index === 1" class="ml-1 grey--text caption">
        +{{ model.length - 1 }} outra(s)...
      </span>
    </template>

<!--    <template v-slot:prepend-item v-if="multiple">-->
<!--      <v-list-item ripple @click="selectAllProducoes">-->
<!--        <v-list-item-action>-->
<!--          <v-icon :color="model ? 'primary' : ''">-->
<!--            {{ iconeProducoes }}-->
<!--          </v-icon>-->
<!--        </v-list-item-action>-->
<!--        <v-list-item-content>-->
<!--          <v-list-item-title>-->
<!--            Selecionar Todas-->
<!--          </v-list-item-title>-->
<!--        </v-list-item-content>-->
<!--      </v-list-item>-->
<!--      <v-divider class="mt-2"></v-divider>-->
<!--    </template>-->
  </v-autocomplete>
</template>
<style>
  .v-select__selections span {
    margin: 2px 4px!important;
  }
</style>
<script type="text/javascript">

export default {
  props: {
    value: {
      type: [String, Object, Array],
      default: '',
    },
    mostraTodosLabel: {
      type: Boolean,
      default: false,
    },
    administrativo: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: [String, Object, Array],
      default: '',
    },
    svi: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Produção',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data: (instance) => ({
    producaoRule: [(v) => !instance.obrigatorio || !!v || 'Selecione a Produção'],
    producoesOptions: [{ id: '', sigla: 'Selecione uma Produção' }],
  }),
  computed: {
    computedLabel () {
      return this.obrigatorio ? `${this.label} *` : this.label
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        if (this.multiple) {
          if (val.length === 0) {
            val = ['']
          }

          let firstIsAll = null
          const getIdByValue = this.getIdByValue

          val = val.reverse().filter(function (item) {
            if (firstIsAll === null) {
              firstIsAll = getIdByValue(item) === ''
              return true
            }

            return !firstIsAll && getIdByValue(item) > 0
          })
        }

        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
    // selecionarTodasProducoes () {
    //   if (this.mostraTodosLabel) {
    //     return this.model.length === this.producoesOptions.length - 1
    //   }
    //
    //   return this.model.length === this.producoesOptions.length
    // },
    // iconeProducoes () {
    //   if (this.model.length === 0) {
    //     return 'mdi-checkbox-blank-outline'
    //   }
    //
    //   return this.selecionarTodasProducoes ? 'mdi-close-box' : 'mdi-minus-box'
    // },
  },
  watch: {
    empresa (currentValue) {
      this.getProducoes(currentValue)
      const producaoId = this.model

      if (this.producoesOptions.filter(function (producao) {
        if (!producaoId) {
          return false
        }

        return [].concat(producaoId).includes(producao.id)
      }).length === 0) {
        this.model = this.getDefaultValue()
      }
    },
  },

  mounted () {
    this.getProducoes(this.empresa)
  },

  methods: {
    // selectAllProducoes () {
    //   this.$nextTick(() => {
    //     if (this.selecionarTodasProducoes) {
    //       this.model = []
    //       return
    //     }
    //
    //     if (this.returnObject) {
    //       this.model = this.producoesOptions
    //       return
    //     }
    //
    //     this.model = this.producoesOptions.map(function (item) {
    //       return item.id
    //     })
    //   })
    // },
    getIdByValue (value) {
      return typeof value === 'object' && value !== null ? value.id : value
    },
    getDefaultValue () {
      return this.multiple ? [] : null
    },
    getProducoes (empresa) {
      const getIdByValue = this.getIdByValue
      const empresaId = [].concat(empresa).map(function (item) {
        return getIdByValue(item)
      })

      if (this.svi) {
        this.producoesOptions = this.$producoes.listProducoesBySvi(empresaId, this.mostraTodosLabel, this.administrativo)
        return
      }

      this.producoesOptions = this.$producoes.list(empresaId, this.mostraTodosLabel, this.administrativo)
    },
  },
}
</script>
