<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<style lang="scss">
  // 3rd party plugins css
  @import "~bootstrap-vue/dist/bootstrap-vue.css";
  @import "~perfect-scrollbar/css/perfect-scrollbar.css";
  @import "~animate.css";
  @import "~@fortawesome/fontawesome-free/css/all.css";
  @import "~line-awesome/dist/line-awesome/css/line-awesome.css";
  @import "assets/plugins/flaticon/flaticon.css";
  @import "assets/plugins/flaticon2/flaticon.css";
  @import "assets/plugins/keenthemes-icons/font/ki.css";

  @import "assets/sass/style.vue";
</style>

<script>
// import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { OVERRIDE_LAYOUT_CONFIG } from '@/core/services/store/config.module'

export default {
  name: 'Itograss',
  mounted () {
    // this.$store.dispatch(VERIFY_AUTH);
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG)
  },
}
</script>
