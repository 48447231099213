import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.headersFiltradas.length > 0)?_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"primary","dark":"","disabled":_vm.disabled,"title":"Controla cabeçalho"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-table-headers-eye")])],1)]}}],null,false,286568977),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VList,[_c(VListItem,[_c(VListItemTitle,[_vm._v("Exibição das colunas")])],1),_vm._l((_vm.headersFiltradas),function(header,index){return _c(VListItem,{key:index},[_c(VListItemAction,[_c(VSwitch,{attrs:{"color":"primary"},model:{value:(header.ativado),callback:function ($$v) {_vm.$set(header, "ativado", $$v)},expression:"header.ativado"}})],1),_c(VListItemTitle,[_vm._v(_vm._s(header.text))])],1)}),(_vm.showItemsPerPage)?[_c(VListItem,[_c(VListItemTitle,[_vm._v("Itens por página")])],1),_c(VListItem,[_c(VSelect,{staticClass:"fit",attrs:{"dense":"","item-value":"id","items":[{
              id: 25,
              text: '25',
            }, {
              id: 50,
              text: '50',
            }, {
              id: 100,
              text: '100',
            }, {
              id: 500,
              text: '500',
            }],"hide-details":"","outlined":""},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)]:_vm._e(),_c(VListItem,[_c(VBtn,{attrs:{"block":"","color":"primary"},on:{"click":_vm.aplicarModificacoes}},[_vm._v("Aplicar")])],1)],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }