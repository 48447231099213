<template>
  <v-menu
      v-if="headersFiltradas.length > 0"
      v-model="dialog"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          dark
          v-bind="attrs"
          class="mr-3"
          v-on="on"
          :disabled="disabled"
          title="Controla cabeçalho"
      >
        <v-icon>mdi-table-headers-eye</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-title>Exibição das colunas</v-list-item-title>
      </v-list-item>
      <v-list-item v-for="(header, index) in headersFiltradas" :key="index">
        <v-list-item-action>
          <v-switch
              v-model="header.ativado"
              color="primary"
          ></v-switch>
        </v-list-item-action>
        <v-list-item-title>{{ header.text }}</v-list-item-title>
      </v-list-item>
      <template v-if="showItemsPerPage">
        <v-list-item>
          <v-list-item-title>Itens por página</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-select
              v-model="itemsPerPage"
              class="fit"
              dense
              item-value="id"
              :items="[{
                id: 25,
                text: '25',
              }, {
                id: 50,
                text: '50',
              }, {
                id: 100,
                text: '100',
              }, {
                id: 500,
                text: '500',
              }]"
              hide-details
              outlined
          />
        </v-list-item>
      </template>
      <v-list-item>
        <v-btn block color="primary" @click="aplicarModificacoes">Aplicar</v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script type="text/javascript">
export default {
  props: {
    excel: {
      type: Boolean,
      default: false,
    },
    pdf: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Object,
    },
    localStorageSaveName: {
      type: String,
    },
    itemsPerPage: {
      type: Number,
    },
    showItemsPerPage: {
      type: Boolean,
      default: false,
    },
  },
  data: (instance) => ({
    dialog: false,
  }),
  watch: {
    headers () {
      this.aplicarModificacoes()
    },
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
        this.$emit('itemsPerPage', this.itemsPerPage)
      },
    },
    headersFiltradas () {
      return this.headers.filter((value) => value.text && !value.obrigatorio && !value.hidden)
    },
  },
  mounted () {
    this.inicia()
    this.aplicarModificacoes()
  },
  methods: {
    inicia () {
      if (!this.localStorageSaveName) {
        return
      }

      const headersOptions = this.getHeaderOptions()

      if (!headersOptions[this.localStorageSaveName]) {
        return
      }

      this.headers.map((header) => {
        if (!header.text || header.obrigatorio) {
          return
        }

        const headerGravado = headersOptions[this.localStorageSaveName]?.filter((item) => item.value === header.value)[0]

        if (headerGravado !== undefined) {
          header.ativado = headerGravado.ativado
        }

        return header
      })
    },
    aplicarModificacoes () {
      this.model = this.headers.filter((value) => value.obrigatorio || value.ativado || value.text === undefined)
      this.dialog = false
      this.updateHeaderOptions()
    },
    getHeaderOptions () {
      let headersOptions = []

      try {
        headersOptions = JSON.parse(window.localStorage.getItem('headersOptions'))
      } catch (e) {
      }

      if (!headersOptions) {
        window.localStorage.setItem('headersOptions', JSON.stringify({}))
        headersOptions = {}
      }

      return headersOptions
    },
    updateHeaderOptions () {
      if (!this.localStorageSaveName) {
        return
      }

      const headersOptions = this.getHeaderOptions()
      headersOptions[this.localStorageSaveName] = this.headers
      window.localStorage.setItem('headersOptions', JSON.stringify(headersOptions))
    },
  },
}
</script>
